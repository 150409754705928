const DIRECTORY_ATTRIBUTES_ROUTES = {
    path: 'directory/attributes',
    name: 'directory-attributes',
    redirect: { name: 'attribute-list' },
    component: () => import('@/views/pages/Directories/attributes/DirectoryAttributesPage.vue'),
    children: [
        {
            path: 'list',
            name: 'attribute-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/attributes/list/DirectoryAttributesListPage.vue')
        },
        {
            path: ':attributeId',
            name: 'current-attributes-attribute',
            meta: { transition: 'slide-right' },
            component: () => import('@/views/pages/Directories/attributes/current/DirectoryAttributesCurrentPage.vue'),
            children: [
                {
                    path: 'attribute',
                    name: 'current-attribute',
                    component: () => import('@/views/pages/Directories/attributes/current/tabs/attribute/tab.tsx')
                },
                {
                    path: 'possible-values',
                    name: 'current-attributes-possible-values',
                    component: () => import('@/views/pages/Directories/attributes/current/tabs/possibleValues/tab.tsx')
                },
                {
                    path: 'contractors',
                    name: 'current-attributes-contractors',
                    component: () => import('@/views/pages/Directories/attributes/current/tabs/contractors/tab.tsx')
                },
                {
                    path: 'departments',
                    name: 'current-attributes-departments',
                    component: () => import('@/views/pages/Directories/attributes/current/tabs/departments/tab.tsx')
                },
                {
                    path: 'goods',
                    name: 'current-attributes-goods',
                    component: () => import('@/views/pages/Directories/attributes/current/tabs/goods/tab.tsx')
                },
                {
                    path: 'marketing-events',
                    name: 'current-attributes-marketing-events',
                    component: () => import('@/views/pages/Directories/attributes/current/tabs/marketingEvents/tab.tsx')
                }
            ]
        }
    ]
};

export default DIRECTORY_ATTRIBUTES_ROUTES;
