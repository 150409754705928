

const DIRECTORY_CATEGORY_GOODS_ROUTES = {
	path: "directory/category-goods",
	name: "directory-goods-category",
	redirect: { name: "category-goods-list" },
	component: () => import("@/views/pages/Directories/goods/category/DirectoryGoodsCategoryPage.vue"),
	children: [
		{
			path: "list",
			name: "category-goods-list",
			meta: { transition: "slide-left" },
			component: () => import("@/views/pages/Directories/goods/category/list/DirectoryCategoryGoodsListPage.vue"),
		},
		{
			path: ":goodCategoryId",
			name: "current-category-category",
			meta: { transition: "slide-right" },
			redirect: { name: 'current-category' },
			component: () => import("@/views/pages/Directories/goods/category/current/DirectoryGoodsCategoryCurrentPage.vue"),
			children: [
				{
					path: "category",
					name: "current-goodCategory",
					meta: { transition: 'slide-left' },
					component: () => import("@/views/pages/Directories/goods/category/current/tabs/basic/tab.tsx"),
				},
				{
					path: "goods",
					name: "current-goodCategory-goods",
					component: () => import("@/views/pages/Directories/goods/category/current/tabs/goods/tab.tsx"),
				},
			],
		},
	],
};

export default DIRECTORY_CATEGORY_GOODS_ROUTES;
