import type { AxiosResponse } from "axios";

import http from "@/api/instance";
import type { BaseRequestConfigType } from "@/api/requests/filters/shorts/types";
import type { BaseRequestParamsType } from "@/api/requests/types";

type NotificationByIdParamsType = {
	id: string;
} & BaseRequestParamsType;

export type NotificationByIdResponseDataItemType = {
	id: string;
	payload: string;
	produceDate: string;
	isRead: boolean;
	chain: {
		chainId: string;
		notificationType: string;
	};
};

type NotificationByIdResponseType = { data: NotificationByIdResponseDataItemType[] };

type NotificationByIdRequestType = (args: BaseRequestConfigType, id: NotificationByIdParamsType) => Promise<AxiosResponse<NotificationByIdResponseType>>;
export const getNotificationById: NotificationByIdRequestType = ({ cancelToken }, id) => http.httpCore.get(`notifications/${id}`, { cancelToken });
