
export const firstLetters = ( str: string )=> {
    if (!str) return
    const words = str?.split(' ');

    const firstLetters = [];

    for (let i = 0; i < words.length; i++) {
        const word = words[i];

        const firstLetter = word.charAt(0).toUpperCase();
        firstLetters.push(firstLetter);
    }

    return firstLetters.join('');
}
