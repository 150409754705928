<script setup lang='ts'>


import {ref, watch, onBeforeMount} from 'vue';
import { useLayout } from '@/layout/composables/layout';
import {useCentralSettingsStore} from "@/stores/centralSettingsStore";
import {setBackgroundImage, setSurfaceOpacity} from "@/components/settingsChapters/viewChapter/utils/setColors";

const emit = defineEmits<{
  'theme:save': [colorTheme: string],
}>()

const props = defineProps<{
  colorTheme?: {key: string, value: string}
}>()

const { changeThemeSettings, layoutConfig } = useLayout();
const centralSettings = useCentralSettingsStore();
const checkedTheme = ref(layoutConfig.value.darkTheme);

// const currentTheme = ref(document.getElementById('theme-css')?.getAttribute('href'))

watch(() => layoutConfig.value.darkTheme, () => {
    layoutConfig.value.darkTheme ? checkedTheme.value = true : checkedTheme.value = false
});

const onChangeThemeEvent = (payload: Event) => {
  // checkedTheme.value = layoutConfig.value.darkTheme;
  checkedTheme.value = !checkedTheme.value;
  const newTheme = !checkedTheme.value ? 'light' : 'dark';

  const elementId = 'theme-css';
  const linkElement = document.getElementById(elementId) as HTMLLinkElement;

  const cloneLinkElement = linkElement.cloneNode(true) as HTMLLinkElement;
  const newThemeUrl = replaceTheme(linkElement.getAttribute('href'), newTheme);
  cloneLinkElement.setAttribute('id', elementId + '-clone');
  let theme: string;
  if (typeof newThemeUrl === 'string') {
    {
      cloneLinkElement.setAttribute('href', newThemeUrl);
      theme = newThemeUrl.split('/')[2];
    }
  }
  cloneLinkElement.addEventListener('load', () => {
    linkElement.remove();
    cloneLinkElement.setAttribute('id', elementId);
    changeThemeSettings(theme, checkedTheme.value);
    setSurfaceOpacity();
  });
  linkElement.parentNode!.insertBefore(cloneLinkElement, linkElement.nextSibling);
  setBackgroundImage(true);
  emit('theme:save', newTheme)
}

const replaceTheme = (themeUrl: string | null, newTheme: "light" | "dark") => {
    if (themeUrl?.includes("/themes/")) {
        if (themeUrl.includes("light")) {
            themeUrl = themeUrl.replace("light", newTheme);
        } else if (themeUrl.includes("dark")) {
            themeUrl = themeUrl.replace("dark", newTheme);
        }
        else if (newTheme === 'dark') {
          themeUrl = '/themes/lara-dark-blue/theme.css';
        }
        else {
          themeUrl = '/themes/lara-light-blue/theme.css';
        }
    }
    return themeUrl;
}

</script>

<template>
    <div class="container-switcher">
        <label class="switcher text-color">
            <input :checked='checkedTheme' @change="onChangeThemeEvent" type="checkbox" name="theme-switcher">
            <div class='flex'>
                <i class="pi pi-moon" style="font-size: 1.1rem" />
                <i class="pi pi-arrow-right arrow" style="font-size: 1.1rem" />
                <i class="pi pi-sun" style="font-size: 1.1rem" />
            </div>
        </label>
    </div>
</template>

<style lang='scss'>
:root {
    --color-arrow: #88888852;
}

.container-switcher {
    display: flex;
    align-items: center;
    justify-content: center;
}


.switcher {
    user-select: none;
    cursor: pointer;
    padding: 16px;
    border-radius: 12px;
    background: var(--switcher-bg);

    &:hover {
        background: var(--surface-hover);
    }

    .arrow {
        margin: 0 20px;
        color: var(--text-color-secondary);
        transition: all 400ms cubic-bezier(0.995, -0.265, 0.855, 0.505);
    }

    input[type=checkbox] {
        height: 0;
        width: 0;
        display:none;

        +div>i {
            transition: all 400ms cubic-bezier(0.995, -0.265, 0.855, 0.505);
        }

        &:checked {
            +div {
                .arrow {
                    transform: rotate(-180deg);
                }

                >:first-child {
                    color: var(--primary-color);
                }
            }
        }

        &:not(:checked) {
            +div {
                >:last-child {
                    color: var(--primary-color);
                }
            }
        }
    }

}
</style>