<script setup lang="ts">
import { onBeforeMount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useLayout } from '@/layout/composables/layout';
import type { ModelType } from '@/layout/AppMenu.vue';

interface AppMenuItemPropsType {
    item: ModelType;
    index: number;
    root?: boolean;
    parentItemKey?: string | null;
    isSideBar?: boolean;
}

const emit = defineEmits<{
    'button:click': [item: object | undefined];
}>();

const props = withDefaults(defineProps<AppMenuItemPropsType>(), {
    index: 0,
    root: true,
    parentItemKey: null
});

const route = useRoute();

const { layoutConfig, layoutState, setActiveMenuItem, onMenuToggle } = useLayout();

const isActiveMenu = ref<boolean | undefined>(false);
const itemKey = ref<string | null>(null);
const icon = ref<string | undefined>(props.item.icon);

onBeforeMount(() => {
    itemKey.value = props.parentItemKey ? `${props.parentItemKey}-${props.index}` : String(props.index);

    const activeItem = layoutConfig.value.activeMenuItem;

    isActiveMenu.value = activeItem === itemKey.value || (activeItem ? activeItem.startsWith(`${itemKey.value}-`) : false);
});

watch(
    () => layoutConfig.value.activeMenuItem,
    (newVal) => {
        isActiveMenu.value = newVal === itemKey.value || newVal?.startsWith(`${itemKey.value}-`);
    }
);

const itemClick = (event: MouseEvent, item: any) => {
    if (item.disabled) {
        event.preventDefault();
        return;
    }

    const { overlayMenuActive, staticMenuMobileActive } = layoutState.value;

    if ((item.to || item.url) && (staticMenuMobileActive || overlayMenuActive)) {
        onMenuToggle();
    }

    if (item.command) {
        item.command({ originalEvent: event, item: item });
    }

    const foundItemKey = item.items ? (isActiveMenu.value ? props.parentItemKey! : itemKey.value!) : itemKey.value!;

    const itemLabel = item.label;
    emit('button:click', itemLabel);
    setActiveMenuItem(foundItemKey);
};

const checkActiveRoute = (item: any) => {
    return item.to.slice(1) ? route.path.slice(1).startsWith(item.to.slice(1)) : route.path === item.to;
};

const onButtonClick = (event) => {
    emit('button:click', event);
};
</script>

<template>
    <li :class="{ 'layout-root-menuitem': root, 'active-menuitem': isActiveMenu }" class="flex flex-column relative" @click="onButtonClick($event, item)">
        <span v-if="item.isNew" class="new">Новое</span>
        <div v-if="root && item.visible !== false && !item.elementary" class="layout-menuitem-root-text">{{ item.label }}</div>
        <a v-if="(!item.to || item.items) && item.visible !== false" :href="item.url" @click="itemClick($event, item)" :class="item.class" :target="item.target" tabindex="0">
            <img v-if="!item.icon && item.logo" :src="item.logo" style="width: 1.3125rem; height: 1.3125rem" class="vertical-align-middle mr-2" />
            <i v-if="!item.logo && item.icon" :class="[icon, { ' elementary-list-icon': item.elementary }]" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <i class="layout-menuitem-dropdown pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </a>
        <router-link v-if="item.to && !item.items && item.visible !== false" @click="itemClick($event, item)" :class="[item.class, { 'active-route': checkActiveRoute(item) }]" tabindex="0" :to="item.to">
            <i :class="item.icon" class="layout-menuitem-icon"></i>
            <span class="layout-menuitem-text">{{ item.label }}</span>
            <i class="pi pi-fw pi-angle-down layout-submenu-toggler" v-if="item.items"></i>
        </router-link>
        <Transition v-if="item.items && !item.visible" name="layout-submenu">
            <ul :style="{ minWidth: isSideBar ? '265px' : '' }" v-show="root ? true : isActiveMenu" class="layout-submenu">
                <template v-for="(child, i) in item.items" :key="i">
                    <app-menu-item :index="i" :item="child" :parentItemKey="itemKey" :root="false"></app-menu-item>
                </template>
            </ul>
        </Transition>
    </li>
</template>

<style lang="scss">
.tooltip-error .p-tooltip-text {
    background-color: var(--red-400);
    color: rgb(255, 255, 255);
}
.tooltip-error.p-tooltip-right .p-tooltip-arrow {
    border-right-color: var(--red-400);
}
.new {
    position: absolute;
    top: 0;
    right: 5px;
    background: var(--green-500);
    color: white;
    padding: 0.25rem;
    z-index: 4;
    font-size: 10px;
    border-radius: 10px;
    text-align: center;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
}

::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: var(--surface-border);
}
</style>
