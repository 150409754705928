import { app } from '@/main';

const add = (args: { summary: string; detail?: string }, severity: 'info' | 'success' | 'warn' | 'error' | undefined) => {
    const summary = args && 'summary' in args ? args.summary : 'Неизвестный источник';
    const detail = args && 'detail' in args ? args.detail : 'Неизвестная ошибка';

    app.config.globalProperties.$toast.add({
        summary: summary,
        detail: detail,
        severity: severity,
        life: 5000
    });
};

export { add };
