const primaryColorVariables = ['50', '100', '200', '300', '400', '500', '600', '700', '800', '900'];

// Функция для определения типа вычисления, принимает координату элемента константы матрицы с правилом вычисления
const defineTypeCalculation = (coordinate: string) => {
    const regex = /^([+-]?)\d+/;
    const [number, sign] = coordinate.match(regex) as [string, string];
    const convertedNumber = Number(number.replace(/^([+-])/, ''));
    if (sign) return [sign, convertedNumber];
    else return ['', 0];
};

// Функция вычисления одного элемента политры, принимает эталонные hsl координаты цвета и элемент константы матрицы с правилом вычисления
const computeCoordinate = (referenceCoordinate: number[], constantCoordinates: string[]) => {
    let computeCoordinates: number[] = [];

    constantCoordinates.forEach((constantCoordinate, coordinateIndex) => {
        let [sign, number]: [string, number] = defineTypeCalculation(constantCoordinate);

        if (sign) {
            if (sign === '+') computeCoordinates[coordinateIndex] = referenceCoordinate[coordinateIndex] + number;
            if (sign === '-') computeCoordinates[coordinateIndex] = referenceCoordinate[coordinateIndex] - number;
        } else computeCoordinates[coordinateIndex] = referenceCoordinate[coordinateIndex];
    });

    return computeCoordinates;
};

// Функция получения палитры primary цветов
export const hslMatrixConvertor = (matrix: string[][], root: HTMLElement, reference: number[]) => {
    for (let matrixIndex = 0; matrixIndex < matrix.length; matrixIndex++) {
        const [hue, saturate, lightness] = computeCoordinate(reference, matrix[matrixIndex]);
        root.style.setProperty(`--primary-${primaryColorVariables[matrixIndex]}`, `hsl(${hue + 'deg ' + saturate + '% ' + lightness + '%'})`);
    }
};
