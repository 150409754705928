import {defineComponent} from "vue";
import RadioButton from "primevue/radiobutton";
import {useLayout} from "@/layout/composables/layout";

const MenuFormat = defineComponent({
    name: 'MenuFormat',
    setup(){
        const { layoutConfig } = useLayout();

        return () => (
            <>
                <div class="grid settings-chapter-header">
                    <div class="col-12">
                        <h4>Тип меню</h4>
                    </div>
                </div>
                <div class="grid pl-6 settings-chapter">
                    <div class="col-5 text-left flex align-items-center">
                        <RadioButton input-id="static" value="static" v-model={layoutConfig.value.menuMode}/>
                        <label for="static" class="ml-2"> Статичное </label>
                    </div>
                    <div class="col-7 flex align-items-center">
                        <RadioButton input-id="dynamic" value="overlay" v-model={layoutConfig.value.menuMode}/>
                        <label for="dynamic" class="ml-2"> Накладывающиеся </label>
                    </div>
                </div>
            </>
        )
    }
});

export default MenuFormat