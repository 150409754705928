
export type newsType = {
    header: string,
    description: string,
    picture: string
}[];


const cursive = (word: string) => {
    return `<i>${word}</i>`;
};

const bold = (word: string) => {
    return `<b>${word}</b>`
};

const color = (word: string, color: string, cursive: boolean = undefined) => {
    if (cursive) return `<span class=${color}><i>${word}</i></span>`
    else return `<span class=${color}>${word}</span>`
}

const lineBreak = () => {
    return `<br/>`
}

export const DESCRIPTION_UPDATES_ITEMS: newsType = [
    {
        header: 'Второй уровень вкладок',
        description: `Ввиду постоянно расширяющегося функционала при работе со справочниками, для удобства пользователей 
        был добавлен второй уровень вложенности.${lineBreak()} Так что если потеряли свою любимую вкладочку, не пугайтесь 
        и не пишите сразу администрации, возможно она в еще одной вкладке со стрелочкой!`,
        picture: '../src/assets/video/secondLevelTabs.mp4'
    },
    {
        header: 'Добавлена работа с подтверждающим документом контрагента',
        description: `Обновлен функционал вкладки "Состояние". Это изменение позволит пользователям более удобно 
        работать с подтверждающими документами и обеспечат более гибкую функциональность.`,
        information: `
        - Добавлено поле подтверждающего документа в разделе "Состояние". Теперь пользователи смогут видеть наименование 
        файла и его формат. <br/>
        - В режиме редактирования вкладки "Состояние" добавлена возможность загрузки файла внизу страницы. Пользователи 
        смогут указать наименование файла и выбрать файл для загрузки. Поддерживаемые форматы файлов включают: 
        pdf, jpg, rtf и doc. <br/>
        - Пользователи теперь смогут скачать подтверждающий документ непосредственно из интерфейса. 
        Это обеспечит удобство доступа к документам и упростит процесс работы с ними. <br/>
        - Добавлена возможность удаления подтверждающего документа из интерфейса редактирования. Пользователи смогут 
        удалить документ, если это необходимо, без необходимости обращаться к другим разделам или приложениям. <br/>
        Эти обновления значительно улучшат функциональность и удобство использования вкладки "Состояние". 
        Мы надеемся, что вы оцените эти изменения и найдете их полезными для своих задач. <br/>
        `,
        picture: '../src/assets/video/confirmDocument.mp4'
    },
    {
        header: 'Переработана вкладка "Сопоставления" в справочнике Контрагентов',
        description: `Мы рады представить вам обновленную вкладку "Сопоставления" в справочнике Контрагентов. 
        Это значительное улучшение, которое добавляет новую функциональность и значимую информацию для вас.
        Теперь вкладка "Сопоставления" содержит подробную информацию о плательщиках и грузополучателях, связанных с 
        каждым Контрагентом. Это позволяет вам создавать, редактировать, удалять и просматривать данные о 
        плательщиках и грузополучателях в удобной таблице. Благодаря новой функциональности вкладки "Сопоставления", вы 
        можете легко управлять информацией о плательщиках и грузополучателях, не покидая таблицу.`,
        picture: '../src/assets/video/mapping.mp4'
    },
    {
        header: 'Прямые контракты',
        description: `Во вкладку "Прямые контракты" Контрагента добавлен функционал, позволяющий работать Контрагенту в 
        трех режимах:${lineBreak()} 
        - Без списка (когда Контрагент работает со всем списком концернов);${lineBreak()} 
        - Белый список;${lineBreak()} 
        - Черный список;${lineBreak()} 
        При переключении с Белого или Черного списка между собой вы просто задаете режим работы с концернами без 
        изменений данных в таблице, но при переключении на режим "Без списка" ранее добавленные Вами концерны удалятся 
        из таблицы`,
        picture: '../src/assets/video/directContracts.mp4'
    },
    {
        header: 'Справочник Брендов',
        description: `Добавлен функционал детального просмотра Бренда, а так же товаров, относящихся к Бренду.Теперь 
        можно не перепрыгивать с вкладки на вкладку, чтобы посмотреть к какому Бренду относится товар`,
        picture: '../src/assets/video/directoryBrands.mp4'
    },
    {
        header: 'Атрибуты',
        description: `Реализован функционал добавления, редактирования и удаления записей для Подразделений и Контрагентов, 
        добавлены выгрузки отчетов`,
        picture: '../src/assets/video/attributes.mp4'
    }
]