const directories = ['atc', 'mnn', 'treaties'];

const toUpperFirstSymbol = (text: string) => {
    const splitted = text.split('');
    const first = splitted[0].toUpperCase();
    const rest = [...splitted];
    rest.splice(0, 1);
    return [first, ...rest].join('');
};

export const directoryRoutesGenerator = () => {
    return directories.map((directory) => ({
        path: `directory/${directory}`,
        name: `directory-${directory}`,
        component: () => import(`@/views/pages/Directories/${directory}/Directory${toUpperFirstSymbol(directory)}Page.vue`)
    }));
};
