import {setBackgroundImage, setPrimaryColor} from "@/components/settingsChapters/viewChapter/utils/setColors";
import {onChangeTheme} from "@/components/settingsChapters/viewChapter/utils/changeTheme";

type SettingsExtendedObjectType = {
    key: string,
    value: {
        theme: string,
        mode: string
    }
};

type SettingsObjectType = {
    key: string,
    value: string
};

type SettingsColorsType = {
    key: string
    value: {primaryColor: string} | string
}

export const loadSettings = async (centralSettingsValues, centralSettings) => {
        /*Данная функция выполняет обработку полученных данных настроек внешнего вида и применяет данные свойства к приложению
        * * centralSettingsValues - Данные настроек передаваемые из хранилища настроек
        * * centralSettings - объект экземпляр хранилища, нужно для сохранения данных в хранилище и для получения их из него*/
        let colorTheme: SettingsExtendedObjectType | SettingsObjectType | undefined;
        if (centralSettingsValues)
            colorTheme = centralSettingsValues?.find((value: {key: string, value: string}) => value.key === 'colorTheme');
        let autoThemeTime: SettingsObjectType | undefined = centralSettingsValues?.find((value: {key: string, value: string}) => value.key === 'autoThemeTime');
        let individualThemeColors: SettingsColorsType | undefined = centralSettingsValues?.find((value: {key: string, value: string}) => value.key === 'individualThemeColors');
        let backgroundImage: SettingsObjectType | undefined = centralSettingsValues?.find((value: {key: string, value: string}) => value.key === 'backgroundImage');
        if (autoThemeTime) centralSettings.automaticThemeTimes = autoThemeTime.value;
        if (individualThemeColors && typeof individualThemeColors.value === 'string') {
            const regex = /hsl[(]|\s|deg|\D|[)]/;
            let match = individualThemeColors.value.split(regex);
            match = match.filter(value => value !== '');
            centralSettings.individualColorsThemes = {h: match[0], s: match[1], l: match[2]};
        }
        else if (individualThemeColors) centralSettings.individualColorsThemes = individualThemeColors.value;
        if (backgroundImage) setBackgroundImage(backgroundImage.value);

        if (colorTheme && autoThemeTime) {
            if (typeof colorTheme.value === 'string' && (colorTheme.value === 'light' || colorTheme.value === 'dark'))
            {
                onChangeTheme(`lara-${colorTheme.value}-blue`, colorTheme.value, autoThemeTime.value);
            }
            else if (typeof colorTheme.value === 'object' && colorTheme.value.theme === 'systemTheme') {
                let mode;
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    mode = 'dark';
                } else {
                    mode = 'light';
                }
                onChangeTheme('systemTheme', mode, autoThemeTime?.value);
            }
            else if (typeof colorTheme.value === 'object'){
                onChangeTheme(colorTheme.value.theme, colorTheme.value.mode, autoThemeTime?.value)
            }
        }

        if (centralSettings.individualColorsThemes)
            setPrimaryColor(centralSettings.individualColorsThemes.primaryColor);
}