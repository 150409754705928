import http from "@/api/instance";
import { BaseRequestParamsType, BaseRequestType } from "@/api/requests/types";

type PersonalClaimByUserType = {
	categoryId: string;
	description: string;
	ruName: string;
	value: string;
};

export type PersonalClaimsByUserResponseType = { data: PersonalClaimByUserType[] };

type PersonalClaimsByUserRequestType = BaseRequestType<{ projectId: number } & BaseRequestParamsType, PersonalClaimsByUserResponseType>;

const getPersonalClaimsByUser: PersonalClaimsByUserRequestType = ({ params: { projectId, pageSize = 1000, pageNumber = 1 } }) =>
	http.httpCore.get("/claim/user/current", { params: { projectId, pageSize, pageNumber } });

export default {
	getPersonalClaimsByUser,
};
