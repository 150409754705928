import { proSystemApiSSEUrl } from '@/env.config';
import { NotificationsResponseDataItemType } from '@/api/requests/notifications/fetch/getNotifications';
import { ref } from 'vue';
import { authControllerInstance } from '@/api/instance';

export type EventSourceNotificationType = {
    notificationChainId: string;
    notificationId: string;
    [key: string]: any;
};

const eventSourceInitDict: { withCredentials: boolean } = { withCredentials: true };
const evenSourceUrl: string = `${proSystemApiSSEUrl}notification`;
let eventSource: EventSource;
const queueSSE = ref([]);
export const eventSourceNotification: EventSourceNotificationType = ref([]);

export const connectSseHub = () => {
    if (!eventSource) {
        authControllerInstance
            .authorizeService(`${proSystemApiSSEUrl}api`)
            .then(() => {
                eventSource = new EventSource(evenSourceUrl, eventSourceInitDict);
                queueSSE.value.map((item) => {
                    item();
                });
            })
            .catch(() => {
                // console.clear();
                console.log('%cSSE authorize service reconnection...', 'color:orange; font-size: 1.5rem');
            });
    }
};

export const eventSourceConnecting = (status: string, notifications) => {
    const listenEventSource = () => {
        eventSource.addEventListener(status, async ({ data }): Promise<EventSourceNotificationType> => {
            eventSourceNotification.value = JSON.parse(data);
            const overwriteNotifications = () => {
                return {
                    ...eventSourceNotification.value,
                    chain: {
                        chainId: eventSourceNotification.value.notificationChainId,
                        notificationType: status
                    },
                    id: eventSourceNotification.value.notificationId
                };
            };

            const targetIndex: number = notifications.value.findIndex((item: NotificationsResponseDataItemType): boolean => item.chain.chainId === eventSourceNotification.value.notificationChainId);
            if (targetIndex !== -1) {
                notifications.value[targetIndex] = overwriteNotifications();
            } else {
                notifications.value.unshift(overwriteNotifications());
            }
        });
    };

    if (eventSource) {
        listenEventSource();
    } else {
        queueSSE.value.push(listenEventSource);
    }
};

export const closeEventSourceConnection = () => {
    eventSource.close();
};
