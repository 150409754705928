import {defineComponent, onBeforeMount, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useLayout} from "@/layout/composables/layout";
import InputSwitch from "primevue/inputswitch";
import Calendar from "primevue/calendar";
import {onChangeTheme, saveAutoThemesProperties} from "@/components/settingsChapters/viewChapter/utils/changeTheme";
import moment from "moment";
import {useCentralSettingsStore} from "@/stores/centralSettingsStore";
import {isEmpty} from "lodash";

const Additional = defineComponent({
    name: 'Additional',
    setup() {
        const { layoutConfig } = useLayout();
        const automaticTheme = ref(false);
        const centralSettings = useCentralSettingsStore();
        const lightThemeOn = ref({});
        const darkThemeOn = ref({});
        const isMounted = ref(false);

        watch(
            () => automaticTheme.value,
            () => {
                if (automaticTheme.value) onChangeTheme('automaticTheme', automaticTheme, {timeLight: lightThemeOn.value, timeDark: darkThemeOn.value});
                else if (layoutConfig.value.theme === 'automaticTheme') onChangeTheme('lara-light-blue', false);
            }
        )

        watch(
            () => layoutConfig.value.theme,
            () => {
                if (layoutConfig.value.theme !== 'automaticTheme') automaticTheme.value = false
            }
        )

        watch(
            () => lightThemeOn.value,
            () => {
                if (layoutConfig.value.theme === 'automaticTheme' && isMounted.value)
                    onChangeTheme('automaticTheme', automaticTheme, {timeLight: lightThemeOn.value, timeDark: darkThemeOn.value});
            }
        )

        watch(
            () => darkThemeOn.value,
            () => {
                if (layoutConfig.value.theme === 'automaticTheme' && isMounted.value)
                    onChangeTheme('automaticTheme', automaticTheme, {timeLight: lightThemeOn.value, timeDark: darkThemeOn.value});
            }
        )

        onBeforeMount(
            () => {
                darkThemeOn.value = !isEmpty(centralSettings.automaticThemeTimes) && centralSettings.automaticThemeTimes.timeDark ? moment(centralSettings.automaticThemeTimes.timeDark).toDate()
                    : ''
                lightThemeOn.value = !isEmpty(centralSettings.automaticThemeTimes) && centralSettings.automaticThemeTimes.timeLight ? moment(centralSettings.automaticThemeTimes.timeLight).toDate()
                    : ''
                automaticTheme.value = layoutConfig.value.theme === 'automaticTheme';
            }
        )

        onMounted(() => isMounted.value = true);

        onBeforeUnmount(
            () => {
                isMounted.value = false
                saveAutoThemesProperties(lightThemeOn.value, darkThemeOn.value)
            }
        )

        return () => (
            <>
                <div class="grid settings-chapter-header">
                    <div class="col-12">
                        <h4>Дополнительные настройки</h4>
                    </div>
                </div>
                <div class="grid pl-6">
                    <div class="text-left">
                        <InputSwitch input-id='waveEffect' v-model={layoutConfig.value.ripple} />
                        <label for="waveEffect" class="mx-2 vertical-align-top">"Эффект волны"</label>
                    </div>
                </div>
                <div class="grid pl-6 my-2">
                    <div class="text-left">
                        <InputSwitch input-id='automaticTheme' v-model={automaticTheme.value}/>
                        <label for="automaticTheme" class="mx-2 vertical-align-top">Автоматическая смена темы</label>
                    </div>
                </div>
                <div class="grid pl-6 my-4">
                    <div class="text-left p-float-label w-full">
                        <Calendar id="lightThemeOn" v-model={lightThemeOn.value} timeOnly hourFormat="24" disabled={!automaticTheme.value} class="w-10"/>
                        <label for="lightThemeOn" class="mx-2">Время включения светлой темы</label>
                    </div>
                </div>
                <div class="grid pl-6 settings-chapter my-4">
                    <div class="text-left p-float-label w-full">
                        <Calendar id="darkThemeOn" v-model={darkThemeOn.value} timeOnly hourFormat="24" disabled={!automaticTheme.value} class="w-10"/>
                        <label for="darkThemeOn" class="mx-2">Время включения темной темы</label>
                    </div>
                </div>
            </>
        )
    }
})

export default Additional