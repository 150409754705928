import { defineStore } from 'pinia';
import type { filterModel, FilterStateType, filterValueTypeModel, initFilterStateActionType } from '@/stores/filters/filtersTypes';
import { ref } from 'vue';
import _ from 'lodash';
import type { FilterStructureType } from '@/constants/filters/types';

export const useFiltersStore = defineStore('filters', () => {
    const filtersSlice = ref<{ [K: string]: FilterStateType }>({});

    const initFilterSliceTable = (tableId: string) => {
        filtersSlice.value[tableId] = {
            errors: {},
            requiredFilters: [],
            filters: {}
        };
    };

    const initStateFilters = (filtersStructure: FilterStructureType): Promise<filterModel> => {
        let newFilterValues: filterModel;
        return new Promise((resolve, reject) => {
            if (filtersStructure) {
                try {
                    filtersStructure &&
                        // Object.entries(filtersStructure).forEach(([scope, filters]) =>
                        //   filters.forEach((filter) => {
                        //       const defaultValue = filter.defaultValue ? filter.defaultValue : 'isMultiple' in filter ? [] : {};
                        //       newFilterValues = initFilterState(newFilterValues, { label: filter.label, id: filter.id, scope: scope, defaultValue });
                        //   })
                        // );
                        Object.entries(filtersStructure).forEach(([scope, filters]) =>
                            Array.from(filters).forEach((filter) => {
                                const defaultValue = filter.defaultValue ? filter.defaultValue : 'isMultiple' in filter ? [] : {};
                                newFilterValues = initFilterState(newFilterValues, { label: filter.label, id: filter.id, scope: scope, defaultValue });
                            })
                        );
                    resolve(newFilterValues);
                } catch (error) {
                    reject(error);
                }
            }
            // else {
            //     reject(new Error('Структура фильтра не указана'));
            // }
        });
    };

    const getInitStateFilters = async (filtersStructure: FilterStructureType, tableId: string) => {
        await initStateFilters(filtersStructure).then((filters) => {
            filtersSlice.value[tableId].filters = { ...filtersSlice.value[tableId].filters, ...filters };
        });
    };

    // Инициализация стейта для фильтра
    const initFilterState = (newFilterValues: filterModel, { id, scope, defaultValue, ...other }: initFilterStateActionType) => {
        const newFilter = {
            ...other,
            error: '',
            isLoading: false,
            selected: defaultValue,
            scope
        };
        return { ...newFilterValues, [id]: newFilter };
    };

    const setFilterValues = ({ id, value, tableId }: { id: string; value: filterValueTypeModel; tableId: string }) => {
        // if (!(id in filtersSlice.value[tableId]['filters'])) throw new Error(`Filter - ${id} is not defined`);
        if (!(id in filtersSlice.value[tableId]['filters'])) filtersSlice.value[tableId].filters = initFilterState(filtersSlice.value[tableId].filters, { id, undefined, value });
        filtersSlice.value[tableId]['filters'][id].selected = value;
    };

    const setFilterError = ({ error, id, tableId }: { id: string; error: string; tableId: string }) => {
        filtersSlice.value[tableId]['filters'][id].error = error;
    };

    const clearAdditional = (tableId: string) => {
        filtersSlice.value[tableId]['filters'] = Object.entries(filtersSlice.value[tableId]['filters']).reduce((acc, [id, filter]) => {
            if (filter.scope === 'main') return { ...acc, [id]: filter };
            else if (_.isArray(filter.selected)) return { ...acc, [id]: { ...filter, selected: [] } };
            else return { ...acc, [id]: { ...filter, selected: {} } };
        }, {});
    };

    const setRequiredFilters = (payload: string[], tableId: string) => {
        filtersSlice.value[tableId].requiredFilters = { ...filtersSlice.value[tableId].requiredFilters, ...payload };
    };

    const resetFiltersSlice = async (tableId: string) => {
        // filtersSlice.value[tableId].filters = {};
    };

    const resetFiltersValue = async (tableId: string) => {
        for (let [id, value] of Object.entries(filtersSlice.value[tableId]['filters'])) {
            if (_.isArray(value.selected)) filtersSlice.value[tableId]['filters'] = { ...filtersSlice.value[tableId]['filters'], [id]: { ...value, selected: [] } };
            else filtersSlice.value[tableId]['filters'] = { ...filtersSlice.value[tableId]['filters'], [id]: { ...value, selected: {} } };
        }
    };

    return {
        filtersSlice,
        initFilterSliceTable,
        getInitStateFilters,
        setFilterValues,
        setFilterError,
        clearAdditional,
        setRequiredFilters,
        resetFiltersSlice,
        resetFiltersValue
    };
});
