<script setup lang="ts">
import { useLayout } from "@/layout/composables/layout";
import iconWarning from "@/assets/icon_warning.svg?component";
import { EventSourceNotificationType } from "@/stores/notifications/eventSourceConnecting";
import { computed, toRefs } from "vue";
import { NOTIFICATION_EVENT_WIZARD_STATUS } from "@/constants/notificationStatusTypes";


const props = defineProps<{
  notification: EventSourceNotificationType,
}>();


const { notification } = toRefs(props);

const { layoutConfig } = useLayout();


const notificationData = computed(() => {
  const { payload } = notification.value;

  return {
    eventStatus: NOTIFICATION_EVENT_WIZARD_STATUS[payload?.status?.id],
    eventName: payload?.eventName,
    eventType: payload?.type?.name,
    eventState: payload?.state?.name,
    eventCode: payload?.eventCode
  };
});

const colorThemeReader = (color1: string, color2: string) => {
  return !layoutConfig.value.darkTheme ? color1 : color2;
};

</script>

<template>
  <div class="z-5 border-round-md w-auto" :style="{
    background: `linear-gradient(150deg, ${colorThemeReader('var(--yellow-100)', 'var(--yellow-600)')} 0%, var(--surface-50) 48.14%)`
  }">
    <div class="flex flex-row p-4 gap-3">
      <div class="w-5rem">
        <div class="h-4rem w-4rem border-circle flex justify-content-center align-items-center shadow-3"
             :style="{background: colorThemeReader('white', 'var(--text-color-secondary)')}">
          <iconWarning class="flex align-items-center text-3xl"
                       :style="{fill: colorThemeReader('var(--yellow-600)', 'var(--yellow-600)')}" />
        </div>
      </div>
      <div class="w-full flex flex-column text-color">
        <div class="flex flex-row justify-content-between align-items-center gap-4">
          <div class="font-bold max-w-27rem text-color-secondary" style="font-size: 1.1rem">
            {{ notificationData.eventName }}
          </div>
          <div class="flex flex-row justify-content-end w-3rem" @click="$emit('delete:toast')">
            <button class="p-link layout-topbar-button border-circle">
              <i class="pi pi-times cursor-pointer p-2" />
            </button>
          </div>
        </div>
        <div class="pt-2">Код мероприятия: <span class="text-color-secondary text-base text-color-secondary font-bold">{{ notificationData.eventCode
          }}</span>
        </div>
        <div class="pt-2">Тип: <span
          class="text-color-secondary text-base text-color-secondary font-bold">{{ notificationData.eventType }}</span>
        </div>
        <div class="pt-2">Состояние мероприятия: <span
          class="text-color-secondary text-base text-color-secondary font-bold">{{ notificationData.eventState }}</span>
        </div>
        <div class="pt-2">Статус: <span class="text-yellow-500">{{ notificationData.eventStatus }}</span></div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

</style>