export const NOTIFICATION_REPORT_STATUS: { [key: number]: string } = {
  0: 'Ошибка при создании отчета',
  1: 'Запрос на создание отчёт принят, ожидает свою очередь на создание',
  2: 'Сбор данных для отчета',
  3: 'Заполнение отчета данными',
  4: 'Файл отчета готов к выгрузке'
}

export const NOTIFICATION_EVENT_WIZARD_STATUS: { [key: number | string]: string } = {
  0   : 'ММ помещено в очередь',
  1   : 'Начало обработки данных по ММ',
  10  : 'Для ММ определён код',
  100 : 'Данные по ММ успешно обработаны',
  '-1': 'Ошибка в ДХ',
  '-2': 'Отмена создания ММ'
}

export const NOTIFICATION_REPORT_STATUS_TYPES: { [key: number | string]: string } = {
  1 : 'Выгрузка по подразделениям',
  2 : 'Выгрузка по контрагентам',
  3 : 'Выгрузка по накладным',
  4 : 'Выгрузка по премиям',
  5 : 'Выгрузка по перемещению',
  6 : 'Выгрузка по маркетинговым мероприятиям контрагента',
  7 : 'Выгрузка по маркетинговым мероприятиям подразделений',
  8 : 'Выгрузка по маркетинговым мероприятиям товаров',
  9 : 'Выгрузка по дебиторской задолженности',
  10 : 'Выгрузка по обратно сальдовой ведомости',
  11 : 'Выгрузка по товародвижению и дистрибьюторам',
  12 : 'Выгрузка по концернам производителей',
  13 : 'Выгрузка по справочникам аптек',
  14 : 'Выгрузка по индивидуальным условиям',
}
