import { defineStore } from 'pinia';
import { ref } from 'vue';

import { userClaims } from '@/api/requests/claims';
import type { PersonalClaimsByUserResponseType } from '@/api/requests/claims/claims';

// Клеймы которых еще нет на беке, но проверка на них на фронте есть
export const missingClaims = ['marketing_event.organization.column.customize', 'marketing_event.organization.filter.apply'];

export const useClaimSettingsStore = defineStore('claim-settings', () => {
    const claims = ref<Set<string> | null>(null);
    const isLoading = ref<boolean>(false);
    const error = ref<Record<string, string> | null>(null);

    const fetchCurrentClaims = async () => {
        setFetching();
        await userClaims
            .getPersonalClaimsByUser({ params: { projectId: Number(import.meta.env.VITE_FRONTEND_PROJECT_ID) } })
            .then(async ({ data }) => {
                await setClaims(data)
                    .then((resolve) => {
                        isLoading.value = false;
                        error.value = null;
                        claims.value = resolve as Set<string>;
                    })
                    .catch((e) => (error.value = e));
            })
            .catch((e) => {
                setError(e);
            });
    };

    const setFetching = () => {
        isLoading.value = true;
        error.value = null;
    };

    const setClaims = (payload: PersonalClaimsByUserResponseType) => {
        return new Promise((resolve, reject) => {
            try {
                resolve(new Set([...missingClaims, ...payload.data.map((claim) => claim.value)]));
            } catch (e) {
                reject(e);
            }
        });
    };

    const setError = (payload: Record<string, string>) => {
        isLoading.value = false;
        error.value = payload;
    };

    return {
        claims,
        isLoading,
        error,
        fetchCurrentClaims
    };
});
