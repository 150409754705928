<script setup lang="ts">
import { useLayout } from "@/layout/composables/layout";
import { EventSourceNotificationType } from "@/stores/notifications/eventSourceConnecting";
import { computed, toRefs } from "vue";
import { NOTIFICATION_REPORT_STATUS, NOTIFICATION_REPORT_STATUS_TYPES } from "@/constants/notificationStatusTypes";
import moment from "moment";
import { downloadFile } from "@/utils/downloadFile/downloadFile";

const props = defineProps<{
  notification: EventSourceNotificationType,
}>();


const { notification } = toRefs(props);

const { layoutConfig } = useLayout();


const notificationData = computed(() => {
  const { payload } = notification.value;

  return {
    reportStatus: NOTIFICATION_REPORT_STATUS[payload?.status?.id],
    eventName: payload?.name,
    reportType: NOTIFICATION_REPORT_STATUS_TYPES[payload?.type?.id]
  };
});

const colorThemeReader = (color1: string, color2: string) => {
  return !layoutConfig.value.darkTheme ? color1 : color2;
};


const downloadHandler = () => downloadFile(
  notification.value.payload.downloadUrl,
  `ПроАптека: ${notification.value.payload.name}_${moment(notification.value.produceDate).format("DD.MM.YYYY HH:mm:ss")}.xlsx`
);
</script>

<template>
  <div class="z-5 border-round-md w-auto" :style="{
    background: `linear-gradient(150deg, ${colorThemeReader('var(--green-100)', 'var(--green-600)')} 0%, var(--surface-50) 48.14%)`
  }">
    <div class="flex flex-row p-4 gap-3">
      <div class="w-5rem">
        <div class="h-4rem w-4rem border-circle flex justify-content-center align-items-center shadow-3"
             :style="{background: colorThemeReader('white', 'var(--text-color-secondary)')}">
          <div class="flex justify-content-center h-2rem w-2rem bg-green-500 border-circle ">
            <i class="pi pi-check flex align-items-center "
               :style="{color: colorThemeReader('white', 'var(--text-color)')}" />
          </div>
        </div>
      </div>
      <div class="w-full flex flex-column text-color">
        <div class="flex flex-row justify-content-between align-items-center gap-4">
          <div class="font-bold max-w-30rem text-color-secondary" style="font-size: 1.1rem">
            {{ notificationData.eventName }}
          </div>
          <div class="flex flex-row justify-content-end w-3rem" @click="$emit('delete:toast')">
            <button class="p-link layout-topbar-button border-circle">
              <i class="pi pi-times cursor-pointer p-2" />
            </button>
          </div>
        </div>
        <div class="pt-2">Статус: <span class="text-green-500">{{ notificationData.reportStatus }}</span></div>
        <div>
          <div class="mt-2 flex align-items-center w-full card cursor-pointer"
               v-if="notification.payload.status.id === 4"
               style="height: auto;  padding: 0 !important;">
            <i class="pi pi-file-excel text-green-500 ml-3" style="font-size: 1.8rem"></i>
            <div class="flex flex-column m-3 w-24rem" @click="downloadHandler">
            <span style="word-wrap: break-word" class="text-color-primary font-medium word-wrap">
              {{ `ПроАптека_${notification.payload.name.replace(/ /g, "_")}_${moment(notification.produceDate).format("DD_MM_YYYY_HH_mm")}`.toUpperCase() }}{{ `.xlsx`
              }}
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>