

const DIRECTORY_BRANDS_ROUTES = {
	path: "directory/brand",
	name: "directory-brand",
	redirect: { name: "brand-list" },
	component: () => import("@/views/pages/Directories/brand/DirectoryBrandPage.vue"),
	children: [
		{
			path: "list",
			name: "brand-list",
			meta: { transition: "slide-left" },
			component: () => import("@/views/pages/Directories/brand/list/DirectoryBrandListPage.vue"),
		},
		{
			path: ":brandId",
			name: "current-brands-brand",
			meta: { transition: "slide-right" },
			component: () => import("@/views/pages/Directories/brand/current/DirectoryBrandCurrentPage.vue"),
			children: [
				{
					path: "brand",
					name: "current-brand",
					component: () => import("@/views/pages/Directories/brand/current/tabs/basic/tab.tsx"),
				},
				{
					path: "goods",
					name: "current-brand-goods",
					component: () => import("@/views/pages/Directories/brand/current/tabs/goods/tab.tsx"),
				},
			],
		},
	],
};

export default DIRECTORY_BRANDS_ROUTES;