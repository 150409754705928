export const enumErrors: { [key: string]: { summary: string, detail?: string } } = {

  USER_AUTHORIZATION: {
    summary: 'Авторизация пользователя',
    detail: 'Возникла ошибка авторизации пользователя в системе. Попробуйте авторизоваться ещё раз или повторите попытку позже'
  },

  USER_SETTINGS: {
    summary: 'Пользовательские настройки',
    detail: 'Ошибка сохранения внесенных настроек системы'
  },

  CONNECTION_FROM_SERVERS: {
    summary: 'Соединение с серверов',
    detail: 'Возникла ошибка. Соединение с сервером отсутствует'
  },

  FETCH_CLAIMS_BY_CURRENT_USER: {
    summary: 'Настройки пользователя',
    detail: 'Ошибка получения разрешений пользователя'
  },

  FETCH_CLAIMS_BY_PROJECT: {
    summary: 'Матрица ролей',
    detail: 'Ошибка при попытке получения разрешений по выбранному проекту'
  },

  FETCH_CLAIMS_ASSIGNED_TO_ROLE: {
    summary: 'Матрица ролей',
    detail: 'Ошибка при попытке получения назначенных разрешений для выбранной роли',
  },

  FETCH_CATEGORIES_BY_ROLE_AND_PROJECT: {
    summary: 'Матрица ролей',
    detail: 'Ошибка при попытке получения категорий для выбранной роли и проекта'
  },

  FETCH_PROJECTS: {
    summary: 'Матрица ролей',
    detail: 'Ошибка при попытке получения списка проектов'
  },

  FETCH_ROLES: {
    summary: 'Матрица ролей',
    detail: 'Ошибка при попытке получения списка ролей',
  },

  ASSIGN_ALL_CLAIMS_IN_CATEGORY_TO_ROLE: {
    summary: 'Матрица ролей',
    detail: 'Ошибка при назначении всех разрешений категории на выбранную роль'
  },

  ASSIGN_CLAIM_TO_ROLE: {
    summary: 'Матрица ролей',
    detail: 'Ошибка назначения разрешения на выбранную роль'
  },

  UNASSIGN_ALL_CLAIMS_IN_CATEGORY_TO_ROLE: {
    summary: 'Матрица ролей',
    detail: 'Ошибка снятия разрешений категории с выбранной роли'
  },

  UNASSIGN_CLAIM_TO_ROLE: {
    summary: 'Матрица ролей',
    detail: 'Ошибка снятия разрешения с выбранной роли'
  },

  ASSIGN_ALL_CLAIMS_IN_CATEGORY_TO_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при назначении всех разрешений категории на выбранного пользователя'
  },

  ASSIGN_CLAIM_TO_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка назначения разрешения на выбранного пользователя'
  },

  UNASSIGN_ALL_CLAIMS_IN_CATEGORY_TO_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка снятия разрешений категории с выбранного пользователя'
  },

  UNASSIGN_CLAIM_TO_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка снятия разрешения с выбранного пользователя'
  },

  FETCH_ALL_USERS: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при попытке получения списка пользователей'
  },

  FETCH_SEARCH_USERS: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при попытке поиска пользователя'
  },

  FETCH_ROLES_BY_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при попытке получения списка назначенных ролей для выбранного пользователя'
  },

  FETCH_CLAIMS_ASSIGNED_TO_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при попытке получения персональных разрешений выбранного пользователя'
  },

  FETCH_CLAIMS_ASSIGNED_TO_USER_TOTAL: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при попытке получения всех разрешений выбранного пользователя'
  },

  ASSIGN_ROLE_TO_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при попытке назначения пользователя на роль'
  },

  UNASSIGN_ROLE_TO_USER: {
    summary: 'Назначение ролей',
    detail: 'Ошибка при попытке снятия пользователя с роли'
  },

  FETCH_EVENT: {
    summary: 'Мероприятие',
    detail: 'Ошибка получения документа мероприятия'
  },

  UPDATE_EVENT_DATA: {
    summary: 'Обновление данных мероприятия',
  },

  EVENT_DATA_EDIT: {
    summary: 'Редактирование данных мероприятия',
  },

  SAVING_DATA_TO_AN_EVENT: {
    summary: 'Сохранение данных мероприятия',
  },

  SAVING_EVENT: {
    summary: 'Сохранение данных мероприятия',
    detail: 'Ошибка сохранения мероприятия',
  },

  EVENT_STATUS_UPDATE: {
    summary: 'Обновление статуса мероприятия',
    detail: 'Не удалось обновить статус мероприятия'
  },

  PROCESSING_EVENT_FILES: {
    summary: 'Загрузка файла(ов)',
  },

  DELETE_EVENT: {
    summary: 'Удаление мероприятий',
    detail: 'Возникла ошибка удаления выбранных мероприятий'
  },

  DELETE_DIRECTORY_ATTRIBUTES: {
    summary: 'Удаление атрибутов',
    detail: 'Возникла ошибка удаления выбранных атрибутов'
  },

  DELETE_MARKETING_EVENT_DISTRIBUTOR: {
    summary: 'Удаление дистрибьютора мероприятия',
    detail: 'Возникла ошибка удаления данных о дистрибьюторе мероприятия'
  },

  DELETE_DIRECTORY_CONTRACTOR: {
    summary: 'Удаление контрагентов',
    detail: 'Возникла ошибка удаления данных о контрагенте'
  },

  DELETE_DIRECTORY_EKG: {
    summary: 'Удаление единых коммерческих групп',
    detail: 'Возникла ошибка удаления данных о единых коммерческих группах'
  },

  DELETE_DIRECTORY_DISTRIBUTOR: {
    summary: 'Удаление дистрибьютора мероприятия',
    detail: 'Возникла ошибка удаления данных о дистрибьюторе'
  },

  DELETE_MARKETING_EVENT_DEPARTMENT: {
    summary: 'Удаление аптек',
    detail: 'Возникла ошибка удаления данных о аптеках'
  },

  DELETE_DIRECTORY_DEPARTMENT: {
    summary: 'Удаление подразделения',
    detail: 'Возникла ошибка при удалении данных о подразделении'
  },

  DELETE_DIRECTORY_ATC: {
    summary: 'Удаление АТХ классификации лекарственных средств',
    detail: 'Возникла ошибка удаления данных о АТХ классификации лекарственных средств'
  },

  DELETE_DIRECTORY_MNN: {
    summary: 'Удаление международных непатентованных наименований',
    detail: 'Возникла ошибка удаления данных о международных непатентованных наименованиях'
  },

  DELETE_DIRECTORY_CONCERN: {
    summary: 'Удаление концернов',
    detail: 'Возникла ошибка удаления данных о концернах'
  },

  DELETE_DIRECTORY_PRODUCER: {
    summary: 'Удаление производителя',
    detail: 'Возникла ошибка удаления данных о производителях'
  },

  DELETE_DIRECTORY_GOODS: {
    summary: 'Удаление товаров',
    detail: 'Возникла ошибка удаления данных о товарах'
  },

  DELETE_DIRECTORY_CATEGORY_GOODS: {
    summary: 'Удаление категорий товаров',
    detail: 'Возникла ошибка удаления категории товаров'
  },

  DELETE_DIRECTORY_GOODS_CATEGORY: {
    summary: 'Удаление категорий товаров',
    detail: 'Возникла ошибка удаления данных о категории товара'
  },

  DELETE_INDIVIDUAL_CONDITIONS: {
    summary: 'Удаление индивидуального условия',
    detail: 'Возникла ошибка удаления данных об индивидуальном условии, попытайтесь позже или обратитесь к администратору'
  },

  DELETE_DIRECTORY_CONTRACTOR_TAB_DEPARTMENT: {
    summary: 'Удаление подразделения контрагента',
    detail: 'Возникла ошибка удаления данных о подразделении контрагента, попытайтесь позже или обратитесь к администратору'
  },

  DELETE_DIRECTORY_CONTRACTOR_TAB_INVOICES: {
    summary: 'Удаление счета контрагента',
    detail: 'Возникла ошибка удаления данных о счете контрагента, попытайтесь позже или обратитесь к администратору'
  },

  DELETE_DIRECTORY_CONTRACTOR_TAB_ATTRIBUTES: {
    summary: 'Удаление аттрибутов контрагента',
    detail: 'Возникла ошибка удаления аттрибутов контрагента, попытайтесь позже или обратитесь к администратору'
  },

  CREATE_INDIVIDUAL_CONDITIONS: {
    summary: 'Создание индивидуального условия',
    detail: 'Возникла ошибка при создании индивидуального условия, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_CONTRACTOR: {
    summary: 'Добавление нового контрагента',
    detail: 'Возникла ошибка при добавлении нового контрагента, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_DEPARTMENT: {
    summary: 'Добавление нового подразделения',
    detail: 'Возникла ошибка при добавлении нового подразделения, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_CONTRACTOR_TAB_DEPARTMENT: {
    summary: 'Добавление нового подразделения контрагента',
    detail: 'Возникла ошибка при добавлении подразделении контрагента, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_CONTRACTOR_TAB_DIRECT_CONTRACTS: {
    summary: 'Добавление нового прямого контракта контрагента',
    detail: 'Возникла ошибка при добавлении прямого контракта контрагента, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_CONTRACTOR_TAB_INVOICES: {
    summary: 'Добавление нового счета контрагента',
    detail: 'Возникла ошибка при добавлении счета контрагента, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_CONTRACTOR_TAB_ACCESS_DOCUMENT: {
    summary: 'Добавление подтверждающего документа',
    detail: 'Возникла ошибка при добавлении подтверждающего документа, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_CONTRACTOR_TAB_ATTRIBUTES: {
    summary: 'Добавление нового аттрибута контрагента',
    detail: 'Возникла ошибка при добавлении аттрибута контрагента, попытайтесь позже или обратитесь к администратору'
  },

  ADD_DIRECTORY_DEPARTMENT_TAB_ATTRIBUTES: {
    summary: 'Добавление нового аттрибута подразделения',
    detail: 'Возникла ошибка при добавлении аттрибута подразделения, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_INDIVIDUAL_CONDITIONS: {
    summary: 'Редактирование индивидуального условия',
    detail: 'Возникла ошибка редактирования данных об индивидуальном условии, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_DIRECTORY_CONTRACTOR_TAB_DEPARTMENT: {
    summary: 'Редактирование подразделения контрагента',
    detail: 'Возникла ошибка редактирования данных о подразделении контрагента, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_DIRECTORY_CONTRACTOR_TAB_INVOICES: {
    summary: 'Редактирование счета контрагента',
    detail: 'Возникла ошибка редактирования данных о счете контрагента, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_DIRECTORY_DEPARTMENT_TAB_SCHEDULE: {
    summary: 'Редактирование графика работы подразделения',
    detail: 'Возникла ошибка редактирования данных о графике работы подразделения, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_DIRECTORY_CONTRACTOR_TAB_ATTRIBUTES: {
    summary: 'Редактирование аттрибутов контрагента',
    detail: 'Возникла ошибка редактирования данных об аттрибутах контрагента, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_DIRECTORY_DEPARTMENT_TAB_ATTRIBUTES: {
    summary: 'Редактирование аттрибутов подразделения',
    detail: 'Возникла ошибка редактирования данных об аттрибутах подразделения, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_DIRECTORY_CONTRACTOR_TAB_ADDITIONAL: {
    summary: 'Редактирование дополнительной информации контрагента',
    detail: 'Возникла ошибка редактирования дополнительной информации контрагента, попытайтесь позже или обратитесь к администратору'
  },

  EDIT_DIRECTORY_DISTRIBUTOR: {
    summary: 'Редактирование дистрибьютора',
    detail: 'Возникла ошибка при редактировании данных о дистрибьюторе'
  },
  CANCEL_EVENT_CREATION: {
    summary: 'Отмена создания мероприятия',
    detail: 'Произошла ошибка при отмене создания мероприятия'
  },
  COPY_EVENT: {
    summary: 'Копирование мероприятия',
    detail: 'Произошла ошибка при копирование данного мероприятия'
  },
  GET_EVENTS: {
    summary: 'Получение списка мероприятий',
    detail: 'При получении списка мероприятий возникла ошибка'
  },
  GET_PRIZE_REPORT: {
    summary: 'Получение списка отчетов по премиям',
    detail: 'При получении списка отчетов по премиям возникла ошибка'
  },
  GET_ORGANIZATION: {
    summary: 'Получение списка организаций',
    detail: 'При получении списка организаций возникла ошибка'
  },
  GET_EVENT_GOODS: {
    summary: 'Получение списка продуктов мероприятия',
    detail: 'При получении списка продуктов мероприятия возникла ошибка'
  },
  GET_EVENT_DISTRIBUTOR: {
    summary: 'Получение списка дистрибьюторов мероприятия',
    detail: 'При получении списка дистрибьюторов мероприятия возникла ошибка'
  },
  GET_ACCESS: {
    summary: 'Получение списка разрешений',
    detail: 'При получении списка организаций возникла ошибка'
  },
  GET_DIRECTORY_DKG: {
    summary: 'Получение динамики ключевых показателей',
    detail: 'При получении динамики ключевых показателей возникла ошибка'
  },
  GET_PARTITION_DEPARTMENTS: {
    summary: 'Получение перемещений в разрезе подразделений',
    detail: 'При получении перемещений в разрезе подразделений возникла ошибка'
  },
  GET_PARTITION_INVOICES: {
    summary: 'Получение перемещений в разрезе накладных',
    detail: 'При получении перемещений в разрезе накладных возникла ошибка'
  },
  GET_SHIPMENT_DETAILING_CONTEXT_INVOICES: {
    summary: 'Получение детализации отгрузки в разрезе накладных',
    detail: 'При получении детализация отгрузки в разрезе накладных возникла ошибка'
  },
  GET_DETAILED_SHIPMENT_DEPARTMENTS: {
    summary: 'Получение детализации отгрузки в разрезе подразделений',
    detail: 'При получении детализация отгрузки в разрезе подразделений возникла ошибка'
  },
  GET_EVENT_GOODS_CATEGORY: {
    summary: 'Получение категории товаров',
    detail: 'При получении категории товаров возникла ошибка'
  },
  GET_DIRECTORY_CONTRACTOR: {
    summary: 'Получение списка контрагентов',
    detail: 'При получении списка контрагентов возникла ошибка'
  },
  GET_DIRECTORY_CONTRACTOR_TAB_GENERAL: {
    summary: 'Получение данных о контрагенте',
    detail: 'При получении данных о контрагенте возникла ошибка'
  },

  GET_DIRECTORY_DEPARTMENT_TAB_GENERAL: {
    summary: 'Получение данных о контрагенте',
    detail: 'При получении данных о контрагенте возникла ошибка'
  },

  GET_DIRECTORY_DEPARTMENT_TAB_SCHEDULE: {
    summary: 'Получение данных о графике работ подразделения',
    detail: 'При получении данных о графике работ подразделения возникла ошибка',
  },

  GET_DIRECTORY_DEPARTMENT_TAB_STORE: {
    summary: 'Получение данных о складах подразделения',
    detail: 'При получении данных о складах подразделения возникла ошибка',
  },

  GET_DIRECTORY_DEPARTMENT_TAB_TRANSITIONSTORY: {
    summary: 'Получение данных об истории переходов подразделения',
    detail: 'При получении данных об истории переходов подразделения возникла ошибка',
  },

  GET_DIRECTORY_DEPARTMENT_TAB_CLASSIFIERS: {
    summary: 'Получение данных о классификаторах подразделения',
    detail: 'При получении данных о классификаторах подразделения возникла ошибка',
  },

  GET_DIRECTORY_DEPARTMENT_TAB_BASIC: {
    summary: 'Получение основных данных подразделения',
    detail: 'При получении основных данных подразделения возникла ошибка',
  },

  GET_DIRECTORY_DEPARTMENT_TAB_ATTRIBUTES: {
    summary: 'Получение данных об атрибутах подразделения',
    detail: 'При получении данных об атрибутах подразделения возникла ошибка',
  },

  GET_DIRECTORY_CONTRACTOR_TAB_BASIC: {
    summary: 'Получение основных данных контрагента',
    detail: 'При получении основных данных контрагента возникла ошибка',
  },

  GET_DIRECTORY_CONTRACTOR_TAB_ATTRIBUTES: {
    summary: 'Получение данных об атрибутах контрагента',
    detail: 'При получении данных об атрибутах контрагента возникла ошибка',
  },

  GET_DIRECTORY_CONTRACTOR_TAB_CONTACTS: {
    summary: 'Получение данных о контактах контрагента',
    detail: 'При получении данных о контактах контрагента возникла ошибка',
  },

  GET_DIRECTORY_CONTRACTOR_TAB_INVOICES: {
    summary: 'Получение данных о счетов контрагента',
    detail: 'При получении данных о счетах контрагента возникла ошибка',
  },

  GET_DIRECTORY_CONTRACTOR_TAB_DIRECT_CONTRACTS: {
    summary: 'Получение списка данных прямых контрактов контрагента',
    detail: 'При получении списка данных прямых контрактов контрагента возникла ошибка'
  },

  GET_DIRECTORY_CONTRACTOR_TAB_ADDITIONALLY: {
    summary: 'Получение списка дополнительных данных контрагента',
    detail: 'При получении списка списка дополнительных данных контрагента возникла ошибка'
  },

  GET_DIRECTORY_EKG: {
    summary: 'Получение списка единых коммерческих групп',
    detail: 'При получении списка единых коммерческих групп возникла ошибка'
  },
  GET_DIRECTORY_DISTRIBUTOR: {
    summary: 'Получение списка дистрибьюторов',
    detail: 'При получении списка дистрибьюторов возникла ошибка'
  },
  GET_DIRECTORY_DEPARTMENT: {
    summary: 'Получение списка подразделений',
    detail: 'При получении списка подразделений возникла ошибка'
  },

  GET_DIRECTORY_ATC: {
    summary: 'Получение списка АТХ классификации лекарственных средств',
    detail: 'При получении списка АТХ классификации лекарственных средств возникла ошибка'
  },
  GET_DIRECTORY_MNN: {
    summary: 'Получение списка международных непатентованных наименований',
    detail: 'При получении списка международных непатентованных наименований возникла ошибка'
  },
  GET_DIRECTORY_CONCERN: {
    summary: 'Получение списка концернов',
    detail: 'При получении списка концернов возникла ошибка'
  },
  GET_DIRECTORY_DIRECTORY_GOODS: {
    summary: 'Получение списка товаров',
    detail: 'При получении списка товаров возникла ошибка'
  },
  GET_DIRECTORY_CATEGORY_GOODS: {
    summary: 'Получение списка категорий товаров',
    detail: 'При получении списка категорий товаров возникла ошибка'
  },
  GET_DIRECTORY_PRODUCER: {
    summary: 'Получение списка производителей',
    detail: 'При получении списка производителей возникла ошибка'
  },
  GET_ACCOUNTS_RECEIVABLE: {
    summary: 'Получение списка дебиторской задолженности',
    detail: 'При получении списка дебиторской задолженности возникла ошибка'
  },
  GET_DIRECTORY_ATTRIBUTES: {
    summary: 'Получение списка атрибутов',
    detail: 'При получении списка атрибутов возникла ошибка'
  },
  GET_INDIVIDUAL_CONDITIONS_LOGGING: {
    summary: 'Получение логирования индивидуального условия',
    detail: 'При получении логирования индивидуального условия возникла ошибка'
  },
  GET_DYNAMICS_OF_BALANCE_SHEET: {
    summary: 'Получение списка оборотно-сальдовой ведомости',
    detail: 'При получении списка оборотно-сальдовой ведомости возникла ошибка'
  },
  GET_DETAILED_SHIPMENT_CONTRACTORS: {
    summary: 'Получение детализации отгрузки контрагентов',
    detail: 'При получении детализации отгрузки контрагентов возникла ошибка'
  },

  // TODO:  ____________________________________________________________________________
  // TODO: ПОКА БУДЕТ ТАК, НО В БУДУЩЕМ НАДО БУДЕТ СДЕЛАТЬ НОРМАЛЬНО, ЧТОБЫ КОНСТАНТЫ
  // TODO: ПЕРЕДАВАЛИСЬ В КОМПОНЕНТ CRUD ВИДЖЕТА, ЛИБО CRUD МОДАЛКИ:
  // TODO: editData.vue, editDataModal.vue, createData.vue, CreateDataModal.vue

  EDIT_ERROR: {
    summary: 'Редактирование данных',
    detail: 'При сохранении изменений возникла ошибка'
  },

  CREATE_ERROR: {
    summary: 'Добавление данных',
    detail: 'При сохранении изменений возникла ошибка'
  },

  // TODO: ПРИ ДОБАВЛЕНИИ ИЛИ УДАЛЕНИЕ В ТАБЛИЦЕ РЕДАКТИРОВАНИЯ МЕРОПРИЯТИЯ, СДЕЛАТЬ ВЫВОД
  // TODO: ОПОВЕЩЕНИЯ С КАЖДЫМ ИМЕНЕМ ТОГО, ЧТО ИМЕННО БЫЛО УДАЛЕНО, ЛИБО ДОБАВЛЕНО
  // TODO: addRowWizardModal.vue, tabComponent.vue

  ADD_ROW_WIZARD_MODAL: {
    summary: 'Добавление данных',
    detail: 'При сохранении изменений возникла ошибка'
  },

  EDIT_ROW_WIZARD: {
    summary: 'Удаление данных',
    detail: 'При удалении возникла ошибка'
  },

  // TODO:  ____________________________________________________________________________

  ADD_EVENT_DISTRIBUTOR: {
    summary: 'Добавление дистрибьютора в мероприятие',
    detail: 'При добавлении дистрибьютора в мероприятие произошла ошибка'
  },

  GET_EVENT_REPORT_FILE: {
    summary: 'Отчеты по мероприятиям',
    detail: 'Ошибка при запросе выгрузки файла, убедитесь, что все фильтры заданы верно'
  },

  FETCH_REPORT: {
    summary: 'Выгрузка отчета',
    detail: 'Произошла непредвиденная ошибка при попытки отправить запрос на создание отчета'
  },

  CALC_EVENT: {
    summary: 'Расчет для мероприятия',
    detail: 'Произошла непредвиденная ошибка при попытки начать расчет мероприятия'
  },

  WIZARD_EVENT_VALIDATION: {
    summary: 'Создание мероприятия',
    detail: 'Убедитесь что все поля заполнены верно'
  },

  CREATE_INDIVIDUAL_CONDITIONS_VALIDATION: {
    summary: 'Создание индивидуального условия',
    detail: 'Убедитесь что все поля заполнены верно'
  },
  CREATE_DIRECTORY_ATTRIBUTES_VALIDATION: {
    summary: 'Создание атрибутов',
    detail: 'Убедитесь что все поля заполнены верно'
  },

  UPDATE_INDIVIDUAL_CONDITIONS_VALIDATION: {
    summary: 'Редактирование индивидуального условия',
    detail: 'Убедитесь что все поля заполнены верно'
  },

  WIZARD_EVENT_FETCH: {
    summary: 'Создание мероприятия',
    detail: 'Ошибка получения данных о изменяемом мероприятии'
  },

  NOTIFICATION_READ: {
    summary: 'Ошибка при прочтении уведомления',
  },

  WIZARD_REPORT_FETCH: {
    summary: 'Выгрузка отчета',
    detail: 'Ошибка при выгрузки отчета'
  },

  CONTRACTOR_VIEW: {
    summary: 'Просмотр контрагента',
    detail: 'Контрагент у подразделения отсутствует'
  },

  DEPARTMENT_VIEW: {
    summary: 'Просмотр подразделения',
    detail: 'Сопоставленное подразделение отсутствует'
  },

  DISTRIBUTOR_VIEW: {
    summary: 'Просмотр дистрибьютора',
    detail: 'Сопоставленный дистрибьютор отсутствует'
  },

  UPLOAD_FILE: {
    summary: 'Выгрузка файла',
    detail: 'Файл выгрузки отсутствует'
  },

  UPLOAD_VALUES_FROM_FILE: {
    summary: 'Выгрузка значений атрибута',
    detail: 'При выгрузке значений атрибута возникла ошибка. Проверьте расширение файла или обратитесь к администрации'
  },

  EVENT_CHECK_DIRECT_CONTRACTS: {
    summary: 'Проверка наличия прямых контрактов',
    detail: 'В участниках мероприятия есть подразделения с прямыми контрактами'
  },

  EVENT_CHECK_DIRECT_CONTRACTS_ERROR: {
    summary: 'Проверка наличия прямых контрактов',
    detail: 'При проверки наличия прямых контрактов произошла ошибка. Попробуйте позже или обратитесь к администрации'
  },

  EVENT_DELETE_DIRECT_CONTRACTS_ERROR: {
    summary: 'Удаление прямых контрактов',
    detail: 'При удалении прямых контрактов произошла ошибка. Попробуйте позже или обратитесь к администрации'
  }
}