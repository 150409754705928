const DIRECTORY_CONTRACTORS_ROUTES = {
    path: 'directory/contractor',
    name: 'directory-contractor',
    redirect: { name: 'contractor-list' },
    component: () => import('@/views/pages/Directories/contractor/DirectoryContractorPage.vue'),
    children: [
        {
            path: 'list',
            name: 'contractor-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/contractor/list/DirectoryContractorListPage.vue')
        },
        {
            path: ':contractorId',
            name: 'current-contractors-contractor',
            meta: { transition: 'slide-right' },
            // redirect: { name: 'current-event-basic' },
            component: () => import('@/views/pages/Directories/contractor/current/DirectoryContractorCurrentPage.vue'),
            children: [
                {
                    path: 'contractor',
                    name: 'current-contractor',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/main/tab.tsx')
                },
                {
                    path: 'attributes',
                    name: 'current-contractor-attributes',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/attributes/tab.tsx')
                },
                {
                    path: 'basic',
                    name: 'current-contractor-basic',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'confirmation-document',
                    name: 'current-contractor-confirmation-document',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/confirmationDocument/ContractorConfirmationDocument.vue')
                },
                {
                    path: 'contacts',
                    name: 'current-contractor-contacts',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/contacts/tab.tsx')
                },
                {
                    path: 'installation',
                    name: 'current-contractor-installation',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/installation/tab.tsx')
                },
                {
                    path: 'change-history',
                    name: 'current-contractor-change-history',
                    meta: { transition: 'slide-right' },
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/contractorChangeHistory/tab.tsx')
                },
                {
                    path: 'mapping',
                    name: 'current-contractor-mapping',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/mapping/tab.tsx')
                },
                {
                    path: 'department',
                    name: 'current-contractor-department',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/department/tab.tsx')
                },
                {
                    path: 'marketing-contracts',
                    name: 'current-contractor-marketing-contracts',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/marketingContracts/tab.tsx')
                },
                {
                    path: 'invoices',
                    name: 'current-contractor-invoices',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/invoices/tab.tsx')
                },
                {
                    path: 'tasks',
                    name: 'current-contractor-tasks',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/tasks/tab.tsx')
                },
                {
                    path: 'additionally',
                    name: 'current-contractor-additionally',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/additionally/tab.tsx')
                },
                {
                    path: 'treaties',
                    name: 'current-contractor-treaties',
                    component: () => import('@/views/pages/Directories/contractor/current/tabs/treaties/tab.tsx')
                }
            ]
        }
    ]
};

export default DIRECTORY_CONTRACTORS_ROUTES;
