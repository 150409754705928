export const enumWarning: { [key: string]: { summary: string, detail?: string } } = {

  VIEW_FUNCTIONAL: {
    summary: 'Функционал',
    detail: 'Функционал еще не реализован'
  },

  FIELD_VALIDATION: {
    summary: 'Валидация полей',
    detail: 'Проверьте, все ли обязательные поля заполнены'
  },

  THE_EVENT_IS_IDENTICAL: {
    summary: 'Копирование мероприятия',
    detail: 'Создаваемое мероприятие не может быть идентична скопированному'
  },

  GET_A_LIST_OF_DKG: {
    summary: 'Получение динамики ключевых показателей',
  },

  GET_A_LIST_OF_PARTITION_DEPARTMENTS: {
    summary: 'Получение перемещений в разрезе подразделений',
  },

  GET_A_LIST_OF_PARTITION_INVOICES: {
    summary: 'Получение перемещений в разрезе накладных',
  },

  GET_A_LIST_OF_DETAILED_SHIPMENT_DEPARTMENTS: {
    summary: 'Получение детализация отгрузки в разрезе подразделений',
  },

  LOAD_REPORT: {
    summary: 'Выгрузка отчета',
    detail: 'Выберите, обязательный для получения отчета, фильтр периода проведения мероприятия'
  },

  LOAD_REPORT_INDIVIDUAL_CONDITIONS: {
    summary: 'Выгрузка отчета',
    detail: 'Выберите, обязательный для получения отчета, фильтр периода индивидуального условия'
  },

}
