<script setup lang="ts">
import Sidebar from 'primevue/sidebar';
import { ViewChapter } from "@/components/settingsChapters/viewChapter/chapter";
import {ref} from "vue";

const props = defineProps({
  simple: {
    type: Boolean,
    default: false
  },
  visible: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits<{
  'update:visible': [];
  'update:themeAbility': [boolean];
}>();

const currentSettingsTab = ref('view');
</script>

<template>
  <Sidebar
      :visible="visible"
      @update:visible="emit('update:visible')"
      position="right"
      :transitionOptions="'.3s cubic-bezier(0, 0, 0.2, 1)'"
      class="layout-config-sidebar w-full xl:w-4 scroll-bar md:mr-2 md:ml-2"
  >
    <h2>Настройки</h2>
    <div class="grid">
      <div class=" col-12 md:col-4">
        <div class="grid">
          <div @click="currentSettingsTab = 'view'" class="col-11 user-menu-button ml-4 text-xl" style="cursor: pointer" :class="{'settings-tab-active': currentSettingsTab === 'view'}">
            Внешний вид
          </div>
        </div>
      </div>
      <ViewChapter />
    </div>
  </Sidebar>
</template>

<style lang="scss" scoped></style>