<script setup lang='ts'>
import { onMounted, onUnmounted, Ref, ref } from 'vue';
import LogoProSystem from '@/assets/logo.svg?component';

type EventNamesType = 'error' | 'authStatus' | 'formVisible'

const props = defineProps<{
    authControllerInstance: {
        iframeElement: HTMLIFrameElement;
        addEventListener(eventName: EventNamesType, callback: any): void;
        removeEventListener(eventName: EventNamesType, callback: any): void;
        selfAuthCheck(): Promise<void>;
    },
}>();

const iFrameVisible = ref(false);
const allowRenderContent = ref(false);
const formContainerRef = ref<Ref<Element> | null>(null);
const { authControllerInstance } = props;

const allowRenderContentHandler = (status: boolean) => {
    if (status) allowRenderContent.value = status;
}

const iFrameVisibleHandler = (visible: boolean) => {
    iFrameVisible.value = visible;
}


onMounted(() => {
    if (formContainerRef.value === null) return;

    if ('iframeElement' in authControllerInstance)
        formContainerRef.value?.appendChild(authControllerInstance.iframeElement)

    // Слушатель события для скрытия iframe
    authControllerInstance.addEventListener('formVisible', iFrameVisibleHandler);

    // Слушатель события предотвращающий рендер приложения если изначально пользователь не авторизован
    authControllerInstance.addEventListener('authStatus', allowRenderContentHandler);

    // Инициация запроса статуса авторизации
    authControllerInstance.selfAuthCheck()

});

onUnmounted(() => {
    authControllerInstance.removeEventListener('formVisible', iFrameVisibleHandler)
    authControllerInstance.removeEventListener('authStatus', allowRenderContentHandler)
});

</script>

<template>
    <div
        ref='formContainerRef'
        :style="{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto',
        width: '100vw',
        height: '100vh',
        zIndex: 99999,
        background: 'transparent',
        display: iFrameVisible ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        }"
    />
    <slot name='app' v-if='allowRenderContent'></slot>

    <div class="cursor-wait" v-if="!allowRenderContent">
        <div class="loader-color-container" />
        <div class="loader-container" />
        <div class='pulse-loader'>
            <LogoProSystem class='text-primary' :style="{ width: '64px', height: '64px', fill: 'var(--primary-color)' }" />
        </div>
    </div>
</template>

<style scoped lang='scss'>
.dialog-container {
    max-width: 650px;
}

.loader-color-container {
    z-index: 1099;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: var(--primary-200);
    opacity: 0.4;
}

.loader-container {
    z-index: 1100;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    backdrop-filter: blur(5px);
}

.pulse-loader:before, .pulse-loader:after {
    z-index: 1101;
    animation-name: pulse-loader;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    box-shadow: 0 0 5px var(--primary-color);
    border-radius: 50%;
    border: 3px solid var(--primary-color);
    content: "";
    display: block;
    height: 256px;
    opacity: 0;
    width: 256px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}
.pulse-loader:after {
    animation-delay: 1s;
}

.pulse-loader > svg {
    z-index: 1101;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

@keyframes pulse-loader {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    60% {
        opacity: 0.4;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}


</style>