<script setup lang="ts">
import { NOTIFICATION_REPORT_STATUS } from '@/constants/notificationStatusTypes'
import { computed, toRefs } from "vue";
import moment from "moment";
import { downloadFile } from "@/utils/downloadFile/downloadFile";
import { notifications } from "@/api/requests/notifications";
import type { NotificationReportStatusItemType } from "@/stores/notifications/reportStatusNotificationsStore";
import { toastNotification } from "@/utils/toastNotification";
import { enumErrors, enumSuccess } from "@/constants/notifications";


const props = defineProps<{
  notification: NotificationReportStatusItemType
  showReadButton?: Boolean,
}>();

const { notification } = toRefs(props);
const emit = defineEmits(['fetchNotification'])

const notificationData = computed(() => {
  const { payload } = notification.value;

  return {
    reportStatus: NOTIFICATION_REPORT_STATUS[payload.status.id],
    eventName: payload.name,
  }
})


const downloadHandler = () => downloadFile(
  notification.value.payload.downloadUrl,
  `ПроАптека: ${notification.value.payload.name}_${moment(notification.value.produceDate).format('DD.MM.YYYY HH:mm:ss')}.xlsx`
)

const onClickReadNotification = async () => {
  try {
    await notifications.readNotificationsByChainId(notification.value.chain.chainId)
    await (async () => emit('fetchNotification'))();
    // toastNotification.add({ ...enumSuccess.NOTIFICATION_READ, detail: notificationData.value.eventName }, 'success')
  } catch(e) {
    toastNotification.add({ ...enumErrors.NOTIFICATION_READ, detail: notificationData.value.eventName }, 'error')
    console.log(e)
  }
}

const statusHandler = computed(() => notification.value.payload.status.id === 0);


</script>

<template>
  <div class="flex w-32rem" :class="showReadButton ? 'border-primary border-left-2' : ''">
    <div class="surface-border border-bottom-1 py-2 w-full">
      <div class="mx-5 my-2">
        <div style="text-align: justify" class="flex justify-content-between">
          <div class="font-medium text-lg align-items-center flex"> {{ notificationData.eventName }} </div>
          <div class="flex align-items-center ml-4" v-if="showReadButton">
            <Button type="button" label="Прочитать" text @click="onClickReadNotification"/>
          </div>
        </div>
        <div class="flex align-items-center py-2">
          <span class="font-light text-sm text-color-secondary">{{ moment(notification.produceDate).format('DD.MM.YYYY HH:mm:ss') }}</span>
          <Divider class="py-2" layout="vertical" />
          <span class="font-light text-sm" :class="{ 'text-red-500': statusHandler, 'text-green-500': !statusHandler}">
            {{ notificationData.reportStatus }}
          </span>
        </div>
        <div class="mt-2 flex align-items-center w-full card cursor-pointer" v-if="notification.payload.status.id === 4" style="height: auto;  padding: 0 !important;">
          <i class="pi pi-file-excel text-green-500 ml-3" style="font-size: 1.8rem"></i>
          <div class="flex flex-column m-3 w-24rem" @click="downloadHandler">
            <span style="word-wrap: break-word" class="text-color-primary font-medium word-wrap">
              {{`ПроАптека_${notification.payload.name.replace(/ /g, '_')}_${moment(notification.produceDate).format("DD_MM_YYYY_HH_mm") }`.toUpperCase()}}{{ `.xlsx` }}
            </span>
<!--            <span class="text-color-secondary font-light text-sm mt-1">3.42 MB</span>-->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>