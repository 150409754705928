<script setup lang="ts">
import { computed, toRefs } from "vue";
import moment from "moment";
import { NOTIFICATION_EVENT_WIZARD_STATUS } from '@/constants/notificationStatusTypes'
import { notifications } from "@/api/requests/notifications";
import type { NotificationMarketingEventItemType } from "@/stores/notifications/eventWizardNotificationsStore";
import { toastNotification } from "@/utils/toastNotification";
import { enumErrors, enumSuccess } from "@/constants/notifications";



const props = defineProps<{
  notification: NotificationMarketingEventItemType,
  showReadButton?: Boolean,
}>();



const { notification, showReadButton } = toRefs(props);
const emit = defineEmits(['fetchNotification'])


const notificationData = computed(() => {
  const { payload } = notification.value;
    return {
      status: NOTIFICATION_EVENT_WIZARD_STATUS[payload.status.id],
      state: payload.state.name,
      type: payload.type.name,
      eventCode: payload.eventCode,
      eventName: payload.eventName,
    }

})

const statusHandler = computed(() => {
  return notification.value.payload.status.id === -1 || notification.value.payload.status.id === -2
})


const onClickReadNotification = async () => {
  try {
    await notifications.readNotificationsByChainId(notification.value.chain.chainId)
    await (async () => emit('fetchNotification'))();
    // toastNotification.add({ ...enumSuccess.NOTIFICATION_READ, detail: notificationData.value.eventName }, 'success')
  } catch(e) {
    toastNotification.add({ ...enumErrors.NOTIFICATION_READ, detail: notificationData.value.eventName }, 'error')
    console.log(e)
  }
}

</script>

<template>
  <div class="flex" :class="showReadButton ? 'border-primary border-left-2' : ''">
    <div class="surface-border border-bottom-1 py-2 w-full">
      <div class="mx-5 my-2">
        <div style="text-align: justify" class="flex justify-content-between">
          <div class="flex flex-column w-full">
            <div class="flex justify-content-between">
              <span class="font-medium text-lg align-items-center flex" :class="showReadButton ? '' : 'mt-2'"> {{ notificationData.eventName }} </span>
              <div class="flex align-items-center ml-4" v-if="showReadButton">
                <Button type="button" label="Прочитать" text @click="onClickReadNotification" />
              </div>
            </div>
            <span class="text-color-secondary font-medium text-sm "  :class="showReadButton ? '' : 'mt-2'">
              Код мероприятия:
              <template v-if="notificationData.eventCode">
                {{ notificationData.eventCode }}
              </template>
              <template v-else>
                <span class='font-italic font-light text-sm'>код не присвоен</span>
              </template>

            </span>
            <div class="flex align-items-center py-2">
              <span class="text-color-secondary font-light text-sm mt-1" v-if="notificationData.state"> {{ notificationData.state }} </span>
              <span class='text-color-secondary font-light text-sm mt-1' v-else> состояние мероприятия не заполнено </span>
              <Divider class="py-2" layout="vertical" />
              <span class="text-color-secondary font-light text-sm mt-1"> {{ notificationData.type }} </span>
            </div>
            <span
              class="text-green-500 font-light text-sm mt-1"
              :class="{ 'text-red-500': statusHandler, 'text-green-500': !statusHandler}"
            >
              {{ notificationData.status }}
            </span>
          </div>
        </div>
        <div class="flex align-items-center py-2">
          <span class="font-light text-sm text-color-secondary">
            {{ moment(notification.produceDate).format('DD.MM.YYYY HH:mm:ss') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>