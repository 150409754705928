<script setup>
import { useLayout } from '@/layout/composables/layout';
import { computed } from 'vue';
import logoWhite from '@/assets/layout/logo-white.png';
import logoDark from '@/assets//layout/logo-dark.png';
import LogoProSystem from '@/assets/logo.svg?component';

const { layoutConfig } = useLayout();

const logoUrl = computed(() => {
    return layoutConfig.value.darkTheme ? logoWhite : logoDark;
});
</script>

<template>
    <div class="layout-footer gap-2">
        <LogoProSystem class='text-primary' :style="{ width: '20px', height: '20px', fill: 'var(--primary-color)' }" />
<!--        <img :src="logoUrl" alt="Logo" height="20" class="mr-2" />-->
        <span class='text-primary'><strong>ПРОСИСТЕМ</strong></span>

        <span class="font-medium">{{ new Date().getFullYear() }}</span>
    </div>
</template>
<style lang="scss" scoped></style>
