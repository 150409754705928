const DIRECTORY_CONCERN_ROUTES = {
    path: 'directory/concern',
    name: 'directory-concern',
    redirect: { name: 'concern-list' },
    component: () => import('@/views/pages/Directories/concern/DirectoryConcernPage.vue'),
    children: [
        {
            path: 'list',
            name: 'concern-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/concern/list/DirectoryConcernListPage.vue')
        },
        {
            path: ':concernId',
            name: 'current-concern-concern',
            meta: { transition: 'slide-right' },
            // redirect: { name: 'current-event-basic' },
            component: () => import('@/views/pages/Directories/concern/current/DirectoryConcernCurrentPage.vue'),
            children: [
                {
                    path: 'concern',
                    name: 'current-concern',
                    component: () => import('@/views/pages/Directories/concern/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'goods',
                    name: 'current-concern-goods',
                    component: () => import('@/views/pages/Directories/concern/current/tabs/goods/tab.tsx')
                },
                {
                    path: 'attributes',
                    name: 'current-concern-attributes',
                    component: () => import('@/views/pages/Directories/concern/current/tabs/attributes/tab.tsx')
                }
            ]
        }
    ]
};

export default DIRECTORY_CONCERN_ROUTES;
