import type { AxiosResponse } from "axios";

import http from "@/api/instance";
import type { BaseRequestConfigType } from "@/api/requests/filters/shorts/types";

export type CurrentUserDataType = {
	id: string;
	email: string;
	fullName: string;
};

type CurrentUserResponseType = { data: CurrentUserDataType };

type CurrentUserRequestType = (args: BaseRequestConfigType) => Promise<AxiosResponse<CurrentUserResponseType>>;

const getCurrentUser: CurrentUserRequestType = ({ cancelToken }) => http.httpCore.get("/user/current", { cancelToken });

export default {
	getCurrentUser,
};
