

const PAYMENT_OBLIGATIONS_ROUTES = {
	path: "realization/payment-obligations",
	name: "payment-obligations",
	redirect: { name: "payment-obligations-dept" },
	component: () => import("@/views/pages/Realization/paymentObligations/PaymentObligationsPage.vue"),
	children: [
		{
			path: "debt",
			name: "payment-obligations-dept",
			component: () => import("@/views/pages/Realization/paymentObligations/tabs/debt/DebtTab.vue"),
		},
		{
			path: "dynamics-shipments-and-payments",
			name: "payment-obligations-dynamics-shipments-and-payments",
			component: () => import("@/views/pages/Realization/paymentObligations/tabs/dynamicShipmentsAndPayments/DynamicShipmentsAndPaymentsTab.vue"),
		},
	],
};

export default PAYMENT_OBLIGATIONS_ROUTES;