import { defineStore } from 'pinia';
import { ref } from 'vue';
import { users } from '@/api/requests/user';

type CurrentUserType = {
    id: string;
    email: string;
    fullName: string;
}

export const useUserSettingsStore = defineStore('user-setting', () => {
    const currentUser = ref<CurrentUserType>({});
    const isLoading = ref(true);
    const error = ref('')


    const getCurrentUser = async () => {
        isLoading.value = true
        try {
            const { data } = await users.getCurrentUser({});
            currentUser.value = data.data;
            isLoading.value = false
        } catch (e) {
            error.value = e
        }

    }

    return {
        currentUser,
        isLoading,
        error,
        getCurrentUser,
    }

})