<script setup lang="ts">
import {ref} from "vue";
import {setBackgroundImage} from "@/components/settingsChapters/viewChapter/utils/setColors";
import {useToast} from "primevue/usetoast";


const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
  'dialog:close': [boolean]
}>()

const image = ref('');
const imageSize = ref<number>();
const progressWidth = ref<number>(12);
const toast = useToast();

const uploadImage = (files: File[]) => {
  const img = new Image();
  img.src = files[0].objectURL;
  img.onload = () => {
    if (img.width >= 1920 || img.height >= 1080) {
      setBackgroundImage(files[0].objectURL, true);
      emit('dialog:close', false);
    }
    else {
      toast.add({severity: 'error', summary: 'Неподходящий размер картинки', detail: 'Загруженная картинка меньше минимального требуемого размера 1920х1080 пикселей', life: 5000})
    }
  }
}
</script>

<template>
  <Dialog :visible="visible" @update:visible="(evt: boolean) => emit('dialog:close', evt)" draggable class='modal-download'>
    <template #header>
      <div class='grid'>
        <h3 class='col-12 text-center vertical-align-middle'>Загрузка изображения</h3>
      </div>
    </template>
    <div class='modal-download'>
      <div class='grid file-loading'>
        <FileUpload
            :multiple='false'
            accept='image/*'
            mode='advanced'
            :fileLimit='1'
            customUpload
        >
          <template #header="{ chooseCallback, clearCallback, files }">
            <div>
              <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined/>
              <Button @click="uploadImage(files)" icon="pi pi-cloud-upload" rounded outlined severity="success" :disabled="!files || files.length === 0"/>
              <Button @click="clearCallback()" icon="pi pi-times" rounded outlined severity="danger" :disabled="!files || files.length === 0"/>
            </div>
          </template>
          <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
            <div class='grid border-round'>
              <div v-if="files.length === 0" class="col-12 text-center ">
                <p class=' vertical-align-middle w-full'>
                  Загрузите или перетащите файл изображения.
                </p>
              </div>
              <div v-else>
                <template v-for="file in files">
                  <div class="grid">
                    <div class="col-2">
                      <img :src="file.objectURL" class="border-round w-5rem h-3rem"/>
                    </div>
                    <div class="col-6">
                      {{file.name}}
                    </div>
                    <div class="col-4">
                      <div class="grid h-1rem pb-3 border-round">
                        <div class="progress-bar w-full h-2rem border-round">
                          <div :class="`progress-bar-value border-round  transition-width transition-duration-200 w-${progressWidth}`">
                            <p class="text-center m-0 p-0"> {{100}}%</p>
                          </div>
                        </div>
                      </div>
                      <div class="grid pt-2">
                        <p>{{(file.size / 1024 / 1024).toFixed(2)}} Мбайт из {{(file.size / 1024 / 1024).toFixed(2)}} Мбайт</p>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </FileUpload>
      </div>
      <div class="grid additional-info mt-2 border-round">
        <div class="col-12">
          <p class='col-12 text-center vertical-align-middle'>
            Размер изображения для загрузки должен быть не меньше чем 1920&times;1080 пикселей.
          </p>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<style scoped lang="scss">

</style>