<script setup lang="ts">
import { ref } from 'vue';
import { firstLetters } from '@/utils/firstLetters';
import { useToast } from 'primevue/usetoast';
import { authControllerInstance } from '@/api/instance';
import { f3ProURL, proAnalyticURL, proLearningURL, proMarketURL, propassportURL, proSystemApiUrl } from '@/env.config';
import LogoProanalytics from '@/assets/images/logos/logoProanalytics.svg';
import LogoPromarket from '@/assets/images/logos/logoPromarket.svg';
import LogoF3pro from '@/assets/images/logos/logoF3pro.svg';
import LogoProlearning from '@/assets/images/logos/logoProlearning.svg';
import AppMenuItem from '@/layout/AppMenuItem.vue';
import type { ModelType } from '@/layout/AppMenu.vue';
import { truncateString } from '@/utils/truncate/truncateString';

const emit = defineEmits<{
    'notifications:open': [[HTMLElement]];
    'update:visible': [boolean];
}>();

const props = defineProps<{
    isLoading: boolean;
    currentUser: {
        id: string;
        email: string;
        fullName: string;
    };
}>();

const toast = useToast();
const otherServices = ref<ModelType[]>([
    {
        items: [
            {
                label: 'Настройки аккаунта',
                icon: 'pi pi-user-edit',
                to: '',
                elementary: true,
                class: 'user-menu-button',
                isNew: false
            },
            {
                label: 'Другие сервисы',
                icon: 'pi pi-th-large',
                elementary: true,
                class: 'user-menu-button',
                isNew: false,
                items: [
                    { label: 'ПРОАНАЛИТИКА', url: proAnalyticURL, class: 'user-menu-button', logo: LogoProanalytics },
                    { label: 'ПРОМАРКЕТ', url: proMarketURL, class: 'user-menu-button', logo: LogoPromarket },
                    { label: 'Ф3ПРО', url: f3ProURL, class: 'user-menu-button', logo: LogoF3pro },
                    { label: 'ПРООБУЧЕНИЕ', url: proLearningURL, class: 'user-menu-button', logo: LogoProlearning }
                ]
            },
            {
                label: 'Изменения на портале',
                icon: 'pi pi-map',
                to: '',
                elementary: true,
                class: 'user-menu-button',
                // isNew: true,
                command: () => {
                    emit('update:visible', true);
                }
            },
            {
                label: 'Обратная связь',
                icon: 'pi pi-comment',
                to: '',
                elementary: true,
                class: 'user-menu-button',
                isNew: false
            }
        ]
    }
]);

const openNotifications = (event: HTMLElement) => {
    emit('notifications:open', [event]);
};

const onLogOut = () => {
    authControllerInstance.logoutService().finally(() => location.reload());
};

const showToastInDevelop = () => {
    toast.add({ severity: 'info', summary: 'Данный раздел ещё в разработке', life: 2000 });
};

const buttonHandlers: { [K: string]: boolean | ((event: HTMLElement) => void) | null } = {
    'Другие сервисы': false,
    ПРОАНАЛИТИКА: false,
    Ф3ПРО: false,
    ПРОМАРКЕТ: false,
    ПРООБУЧЕНИЕ: false,
    Оповещения: openNotifications,
    'Настройки аккаунта': null,
    'Обратная связь': null
};

const onButtonClick = (target: { target: HTMLElement }) => {
    const buttonLabel: string = target.target.outerText;
    const handler = buttonHandlers[buttonLabel];
    if (handler === null) showToastInDevelop();
    else if (handler) handler(target);
};
</script>

<template>
    <div class="flex gap-2">
        <div class="flex align-items-center p-0">
            <Skeleton v-if="isLoading" shape="circle" size="3rem" />
            <span v-if="!isLoading" class="border-circle w-3rem h-3rem m-2 bg-primary text-gray-900 font-bold flex align-items-center justify-content-center select-none">{{ firstLetters(currentUser.fullName) }}</span>
        </div>
        <div class="flex flex-column lg:text-left p-0 my-auto">
            <Skeleton v-if="isLoading" width="6rem" height="1.2rem" class="mb-2 ml-auto"></Skeleton>
            <div v-if="!isLoading" class="text-color font-semibold">{{ currentUser.fullName }}</div>
            <Skeleton v-if="isLoading" width="12rem" height=".7rem" class="ml-auto"></Skeleton>
            <div v-if="!isLoading" class="text-sm text-color-secondary font-light" v-tooltip.bottom="currentUser?.email.length > 28 ? currentUser?.email : undefined">{{ truncateString(currentUser.email, 32) }}</div>
        </div>
    </div>
    <div class="flex flex-column row-gap-2" style="font-size: 1rem; user-select: none">
        <Divider layout="horizontal" class="mb-0 mx-0" />
        <ul class="layout-menu">
            <template v-for="(item, index) in otherServices" :key="item">
                <app-menu-item :item="item" :index="index" @button:click="item?.command ? item?.command : onButtonClick" />
            </template>
        </ul>
        <Divider layout="horizontal" class="my-1 mx-0" />
        <div class="p-2 user-menu-button" @click="onLogOut" style="cursor: pointer">
            <i class="pi pi-sign-out vertical-align-middle pr-2 elementary-list-icon ml-1 p-1"></i>
            Выйти из аккаунта
        </div>
    </div>
    <Toast />
</template>

<style scoped lang="scss"></style>
