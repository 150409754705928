import type { AxiosResponse } from "axios";

import http from "@/api/instance";
import type { BaseRequestConfigType } from "@/api/requests/filters/shorts/types";
import type { BaseRequestParamsType } from "@/api/requests/types";

type NotificationsParamsType = {
	readFilter: string;
	chainIds?: string[];
	maxCountInChain?: number;
	notificationType?: string;
} & BaseRequestParamsType;

export type NotificationsResponseDataItemType = {
	id: string;
	payload: string;
	produceDate: string;
	isRead: boolean;
	chain: {
		chainId: string;
		notificationType: string;
	};
};

type NotificationsResponseType = {
	data: {
		items: NotificationsResponseDataItemType[];
	};
};

type NotificationsRequestType = (args: { params: NotificationsParamsType } & BaseRequestConfigType) => Promise<AxiosResponse<NotificationsResponseType>>;
export const getNotifications: NotificationsRequestType = ({ params, cancelToken }) => http.httpCore.get(`notifications`, { params, cancelToken });
