import { defineComponent} from 'vue';
import IndividualTheme from "@/components/settingsChapters/viewChapter/UI/individualTheme";
import Theme from '@/components/settingsChapters/viewChapter/UI/theme';
import MenuFormat from "@/components/settingsChapters/viewChapter/UI/menuFormat";
import InputsFormat from "@/components/settingsChapters/viewChapter/UI/inputsFormat";
import Scale from "@/components/settingsChapters/viewChapter/UI/scale";
import Additional from "@/components/settingsChapters/viewChapter/UI/additional";
import BackgroundImage from "@/components/settingsChapters/viewChapter/UI/backgroundImage";

export const ViewChapter = defineComponent({
        name: 'viewChapter',
        emits: ['update:visible', 'update:themeAbility'],
        setup(){
            return () => (
                <div class="col-8 m-0">
                    <h2>Внешний вид</h2>
                    <div class="grid settings-chapter-header">
                        <div class="col-12">
                            <h4>Выбор темы</h4>
                        </div>
                    </div>
                    <Theme />
                    <IndividualTheme />
                    <BackgroundImage />
                    <MenuFormat />
                    <InputsFormat />
                    <Scale />
                    <Additional />
                </div>
            )
        }
    });