const DIRECTORY_COMPANY_NET_ROUTES = {
    path: 'directory/companyNet',
    name: 'directory-companyNet',
    redirect: { name: 'companyNet-list' },
    component: () => import('@/views/pages/Directories/companyNet/DirectoryCompanyNetPage.vue'),
    children: [
        {
            path: 'list',
            name: 'companyNet-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/companyNet/list/DirectoryCompanyNetListPage.vue')
        },
        {
            path: ':companyNetId',
            name: 'current-companyNet-company-net',
            meta: { transition: 'slide-right' },
            // redirect: { name: 'current-event-basic' },
            component: () => import('@/views/pages/Directories/companyNet/current/DirectoryCompanyNetCurrentPage.vue'),
            children: [
                {
                    path: 'companyNet',
                    name: 'current-companyNet',
                    component: () => import('@/views/pages/Directories/companyNet/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'contractors',
                    name: 'current-companyNet-contractors',
                    component: () => import('@/views/pages/Directories/companyNet/current/tabs/contractor/tab.tsx')
                }
            ]
        }
    ]
};

export default DIRECTORY_COMPANY_NET_ROUTES;
