const WIZARD_MARKETING_EVENTS_ROUTES = {
    path: '/event/:originalEventId?/:action/:eventId?/:eventType?',
    name: 'event',
    component: () => import('@/views/wizards/MarketingEvent/TheStepperComponent.vue'),
    redirect: { name: 'create' },
    children: [
        {
            path: 'step/first',
            name: 'create',
            component: () => import('@/views/wizards/MarketingEvent/steps/one/TheStepOneComponent.vue'),
            meta: {
                idRequired: false
            }
        },
        {
            path: 'step/second',
            name: 'info',
            component: () => import('@/views/wizards/MarketingEvent/steps/two/TheStepTwoComponent.vue'),
            meta: {
                idRequired: true
            }
        },
        {
            path: 'step/third',
            name: 'loading',
            component: () => import('@/views/wizards/MarketingEvent/steps/three/TheStepThreeComponent.vue'),
            meta: {
                idRequired: true
            }
        },
        {
            path: 'step/fourth',
            name: 'parsing',
            component: () => import('@/views/wizards/MarketingEvent/steps/four/TheStepFourComponent.vue'),
            meta: {
                idRequired: true
            }
        },
        {
            path: 'step/fifth',
            name: 'result',
            component: () => import('@/views/wizards/MarketingEvent/steps/five/TheStepFiveComponent.vue'),
            meta: {
                idRequired: true
            }
        },
        {
            path: 'step/sixth',
            name: 'payment',
            component: () => import('@/views/wizards/MarketingEvent/steps/six/TheStepSixComponent.vue'),
            meta: {
                idRequired: true
            }
        }
    ]
};

export default WIZARD_MARKETING_EVENTS_ROUTES;
