import type { AxiosResponse } from "axios";

import http from "@/api/instance";
import type { BaseRequestType } from "@/api/requests/types";

type UserSettingCreateRequestType = BaseRequestType<{ data: string }, void>;
type UserSettingGetRequestType = BaseRequestType<null, AxiosResponse & { data: { data: { items: string } } }>;
type UserSettingUpdateRequestType = BaseRequestType<{ data: { [K: string]: any } }, void>;

type TableSettingCreateRequestType = BaseRequestType<{ data: string; query: { tableId: string } }, void>;
type TableSettingGetRequestType = BaseRequestType<{ query: { tableId: string } }, AxiosResponse & { data: { data: { items: string } } }>;
type TableSettingUpdateRequestType = BaseRequestType<{ data: string; query: { tableId: string } }, void>;

const setUserSettings: UserSettingCreateRequestType = ({ params: { data }, cancelToken }) => http.httpCore.post("user/setting", data, { cancelToken });
const getUserSettings: UserSettingGetRequestType = ({ cancelToken }) => http.httpCore.get("user/setting", { cancelToken });
const updateUserSettings: UserSettingUpdateRequestType = ({ params: { data }, cancelToken }) => http.httpCore.put("user/setting", data, { cancelToken });

const setTableSettings: TableSettingCreateRequestType = ({ params: { data, query }, cancelToken }) =>
	http.httpCore.post("user/setting/table", data, { params: query, cancelToken });
const getTableSettings: TableSettingGetRequestType = ({ params: { query }, cancelToken }) => http.httpCore.get("user/setting/table", { params: query, cancelToken });
const updateTableSettings: TableSettingUpdateRequestType = ({ params: { data, query }, cancelToken }) =>
	http.httpCore.put("user/setting/table", data, { params: query, cancelToken });

export default {
	setUserSettings,
	getUserSettings,
	updateUserSettings,
	setTableSettings,
	getTableSettings,
	updateTableSettings,
};
