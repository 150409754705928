<script lang="ts" setup>
import { onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { useClaimSettingsStore } from '@/stores/claimSettings';
import { useUserSettingsStore } from '@/stores/userSettingsStore';
import { useUnreadNotificationsReportStatusStore } from '@/stores/notifications/reportStatusNotificationsStore';
import { useUnreadNotificationEventWizardStatusStore } from '@/stores/notifications/eventWizardNotificationsStore';
import { closeEventSourceConnection } from '@/stores/notifications/eventSourceConnecting';
import NotificationToasts from '@/components/toasts/notifications/notificationToasts.vue';
import { onChangeTheme } from '@/components/settingsChapters/viewChapter/utils/changeTheme';
import { useCentralSettingsStore } from '@/stores/centralSettingsStore';
import { useLayout } from '@/layout/composables/layout';
import { connectSseHub } from '@/stores/notifications/eventSourceConnecting';
import moment from 'moment';
import { loadSettings } from '@/utils/themeSettingsLoad';
import { storeToRefs } from 'pinia';
import backgroundImagePath from '@/assets/background.svg';
import { Snow } from '@/holidays/newYear/snow';

const userSettings = useUserSettingsStore();
const claimSettings = useClaimSettingsStore();
const centralSettings = useCentralSettingsStore();
const notificationsReportStore = useUnreadNotificationsReportStatusStore();
const notificationEventStore = useUnreadNotificationEventWizardStatusStore();

const { layoutConfig, changeHolidayTheme } = useLayout();
const { getCurrentUser } = userSettings;
const { fetchCurrentClaims } = claimSettings;
const { fetchReportStatusNotifications } = notificationsReportStore;
const { fetchMarketingEventsNotifications } = notificationEventStore;
const { isBackgroundImage } = storeToRefs(centralSettings);

const isMounted = ref(false);
const currentTime = ref(moment().format('mm'));

const loadThemeSettings = () => {
    // Функция получения и установки данных настроек внешнего вида.
    // В ней вызывается метод из стора настроек и вызывается функция, которая заполняет данные настроек полученные с сервера
    const userCentralSettings = centralSettings.fillAllCentralSettings();
    userCentralSettings.then((centralSettingsValues) => {
        loadSettings(centralSettingsValues, centralSettings);
        isMounted.value = true;
    });
};

const unRegisterServiceWorker = () => {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
};

// Данный вотчер выполняется каждую секунду и проверяет надо ли переключать автоматически с светлой на темную тему или наоборот
watch(
    () => currentTime.value,
    () => {
        if (isMounted.value) onChangeTheme(layoutConfig.value.theme, layoutConfig.value.darkTheme ? 'dark' : 'light', centralSettings.automaticThemeTimes);
    }
);

// Каждую секунду получает текущее время и сохраняет его в переменную
setInterval(() => (currentTime.value = moment().format('HH:mm')), 1000);

onBeforeMount(async () => {
    unRegisterServiceWorker();
    loadThemeSettings();
    setTimeout(getCurrentUser, 0);
    await fetchCurrentClaims();
    await fetchReportStatusNotifications();
    await fetchMarketingEventsNotifications();
    connectSseHub();
    // changeHolidayTheme(layoutConfig.value.holidayTheme);
});

onBeforeUnmount(() => {
    closeEventSourceConnection();
});
</script>

<template>
    <NotificationToasts />
    <div
        :style="{
            ...(!isBackgroundImage ? { backgroundImage: `url(${backgroundImagePath})`, backgroundSize: '85%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' } : {})
        }"
    >
        <router-view />
    </div>
</template>
<style lang="scss">
canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
</style>
