import {useLayout} from "@/layout/composables/layout";
import {useCentralSettingsStore} from "@/stores/centralSettingsStore";
import moment from "moment";
import {setBackgroundImage, setSurfaceOpacity} from "@/components/settingsChapters/viewChapter/utils/setColors";

type AutoThemeTimeType = {
    timeDark: string,
    timeLight: string,
}

const { changeThemeSettings } = useLayout();

const saveSettings = (colorTheme: string, mode: string) => {
    const centralSettings = useCentralSettingsStore();
    let settingsObject: any = {};
    settingsObject['colorTheme'] = {theme: colorTheme, mode: mode};
    centralSettings.updateCentralSettings(settingsObject);
}

export const saveAutoThemesProperties = (timeLight, timeDark) => {
    const centralSettings = useCentralSettingsStore();
    let settingsObject: any = {};
    settingsObject['autoThemeTime'] = {
        timeLight: timeLight,
        timeDark: timeDark
    };
    if (centralSettings.automaticThemeTimes) {
        centralSettings.automaticThemeTimes.timeDark = timeDark;
        centralSettings.automaticThemeTimes.timeLight = timeLight;
    }
    else {
        centralSettings.automaticThemeTimes = {timeDark: timeDark};
        centralSettings.automaticThemeTimes = {timeLight: timeLight};
    }
    centralSettings.updateCentralSettings(settingsObject);
}

export const saveIndividualColorsProperties = (primaryColor) => {
    const centralSettings = useCentralSettingsStore();
    let settingsObject: any = {};
    settingsObject['individualThemeColors'] = {
        primaryColor: primaryColor
    };
    centralSettings.updateCentralSettings(settingsObject);
}

export const saveBackgroundImageProperties = (image) => {
    const centralSettings = useCentralSettingsStore();
    let settingsObject: any = {};
    settingsObject['backgroundImage'] = image;
    centralSettings.updateCentralSettings(settingsObject);
}

const getCurrentAutoTheme = (timeLight, timeDark) => {
    const currentTime = moment();
    if (currentTime.format('HH:mm') >= timeDark.format('HH:mm')
        || currentTime.format('HH:mm') <= timeLight.format('HH:mm')) return 'lara-dark-blue';
    else return 'lara-light-blue';
}

export const onChangeTheme = (theme: string, mode: string, time: AutoThemeTimeType | undefined): void => {
    /*Данная функция вызывается для изменения текущей темы. Она может менять текущие цветовые гаммы приложения и устанавливать картинку на фон
    * * theme - определяет какая тема используется. Возможные значения: 'lara-light-blue', 'lara-dark-blue', 'arya-blue', 'automaticTheme', 'systemTheme'
    * * mode - определяет темная или светлая тема используется. Возможные значения: 'light', 'dark'
    * * time - объект с временем включения. Используется для передачи времени включения светлой или темной темы при theme === 'automaticTheme'*/
    if (theme && theme?.length > 0) {
        const elementId = 'theme-css';
        const linkElement = document.getElementById(elementId) as HTMLLinkElement;
        const oldTheme = linkElement.getAttribute('href');
        if (!oldTheme.includes(theme)) {
            const cloneLinkElement = linkElement.cloneNode(true) as HTMLLinkElement;
            let newThemeUrl;
            if (theme === 'automaticTheme')
                newThemeUrl = `/themes/${getCurrentAutoTheme(moment(time.timeLight), moment(time.timeDark))}/theme.css`
            else
                newThemeUrl = theme.includes('theme.css') ? theme : theme !== 'systemTheme' ? `/themes/${theme}/theme.css`
                    : mode === 'dark' ? `/themes/arya-blue/theme.css` : `/themes/lara-light-blue/theme.css`;
            cloneLinkElement.setAttribute('href', newThemeUrl);
            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', elementId);
                changeThemeSettings(theme, mode === 'dark');
                setSurfaceOpacity();
            });
            linkElement.parentNode!.insertBefore(cloneLinkElement, linkElement.nextSibling);
            saveSettings(theme, mode)
        }
        setBackgroundImage(true);
    }
};