<script setup lang='ts'>
import { ref } from 'vue';
import { DESCRIPTION_UPDATES_ITEMS } from '@/components/userMenu/descriptionUpdatesConfig';

const props = withDefaults(
    defineProps<{
        visible: boolean;
        header: string;
    }>(),
    {
        header: ''
    }
);


const emit = defineEmits<{
    'update:visible': [boolean];
}>();

const values = ref(DESCRIPTION_UPDATES_ITEMS);
const isMaximizable = ref(false);

const responsiveOptions = ref([
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1
    },
    {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
]);



</script>

<template>
    <Dialog
        :visible="visible"
        @update:visible="(value: boolean) => emit('update:visible', value)"
        maximizable :header="header"
        modal
        :style="{ maxWidth: isMaximizable ? '100vw' : '65vw' }"
        @maximize='(event: Event) => {isMaximizable = true}'
        @unmaximize='(event: Event) => {isMaximizable = false}'
    >
        <Carousel :value="values" :numVisible="1" :numScroll="1" :responsiveOptions="responsiveOptions" circular>
            <template #item="{ data }">
                <div v-if='!isMaximizable' class="block max-w-min relative mx-auto video-container">
                    <video :width="1024" :height="576" class="block mx-auto" autoplay muted loop id="myVideo">
                        <source :src="data?.picture" type="video/mp4">
                    </video>
                    <div class="w-full bg-black-alpha-40 content flex flex-column">
                        <div class='p-4 my-auto flex flex-column gap-2'>
                            <h2 class="text-primary">{{ data?.header }}</h2>
                            <div class='w-full'>
                                <span class="words text-white" v-html="data?.description"></span>
                            </div>

                            <div class='pt-4'>
                                <Button type="button" outlined @click="data?.command">
                                    <div class='flex gap-2 align-items-center'>
                                        <span>Перейти к просмотрю обновления</span>
                                        <i class="pi pi-arrow-right" ></i>
                                    </div>

                                </Button>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-if='isMaximizable'>
                    <div class='flex gap-4'>
                        <div>
                            <video :width="1188 " :height="579"  class="block border-round" autoplay muted loop id="myVideo">
                                <source :src="data?.picture">
                            </video>
                        </div>
                        <div class='card flex'>
                            <div class='p-4 my-auto flex flex-column gap-2'>
                                <h2 class="text-primary">{{ data?.header }}</h2>
                                <div class='w-full'>
                                    <span class="words" v-html="data?.description"></span>
                                </div>
                                <div class='pt-4'>
                                    <Button type="button" outlined @click="data?.command">
                                        <div class='flex gap-2 align-items-center'>
                                            <span>Перейти к просмотру обновления</span>
                                            <i class="pi pi-arrow-right" ></i>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class='flex w-full flex-column mt-4 card'>
                        <span v-if='!data?.information'>
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci dolorem doloremque et
                            numquam repudiandae suscipit tenetur unde, vel vero voluptates voluptatibus voluptatum.
                            A consectetur culpa dignissimos error harum mollitia quibusdam tempore totam veritatis
                            voluptatibus? Accusantium aliquid aperiam aut blanditiis cum cumque cupiditate deleniti
                            dolore doloribus dolorum earum eius error, est eveniet excepturi fugiat incidunt ipsum,
                            laudantium libero magnam minima modi natus non porro possimus provident, qui quia quibusdam
                            quis quos rem repellat reprehenderit repudiandae similique sit totam unde? Accusamus dolorum,
                            est fuga itaque labore nostrum odit pariatur perspiciatis sapiente similique sunt velit
                            voluptatibus. Aspernatur cupiditate distinctio est ipsa, obcaecati quo voluptas. Accusamus,
                            adipisci, aliquam asperiores aut blanditiis consectetur cupiditate delectus dignissimos
                            dolor dolores doloribus eos error excepturi exercitationem fugiat fugit harum illum ipsam,
                            libero non odio placeat porro possimus quaerat quas quasi quo repellendus sed sit soluta
                            sunt suscipit totam ut vel velit voluptates voluptatibus! Amet facilis illum incidunt
                            laboriosam libero modi necessitatibus, optio perferendis quidem ratione. Amet beatae
                            dolor earum excepturi iste placeat qui repellendus unde. A eligendi eveniet, exercitationem
                            iste nihil omnis quidem recusandae, reiciendis reprehenderit saepe sit totam! Commodi
                            doloribus dolorum error, esse facere magni molestias mollitia nisi nulla optio quibusdam
                            rerum totam vitae voluptas voluptatibus? Accusantium blanditiis consequuntur delectus
                            deleniti deserunt, dignissimos dolorem doloremque doloribus ea laborum laudantium minima
                            neque numquam quas quos, reprehenderit temporibus. Culpa, dolorum eius enim iusto laboriosam
                            quos repellat tempore velit! A aut expedita explicabo harum ipsa nobis odio perferendis
                            praesentium, qui quis, reprehenderit sed ullam? Consequuntur exercitationem itaque natus
                            optio voluptatum.
                        </span>
                        <span v-if='data?.information' v-html="data?.information" />
                    </div>
                </div>
            </template>
        </Carousel>
    </Dialog>

</template>

<style lang='scss'>
.words {
    width: 740px;
    height: auto;
    word-wrap: break-word;
}
//
//.video-container {
//    margin: -30px auto;
//}

.content {
    bottom: 40px;
    width: 100%;
    height: auto;
    position: absolute;
    opacity: 0;
    transition: opacity .3s ease-out;

    div {
        bottom: 0;
    }

    &:hover {
        opacity: 1;
        transition: opacity .5s .1s ease-in;
    }

}

.p-carousel .p-carousel-indicators {
    position: absolute;
    bottom: 0;
    width: 100%;
}

</style>