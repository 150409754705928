import { log10 } from 'chart.js/helpers';
import { hslMatrixConvertor } from '@/components/settingsChapters/viewChapter/utils/hslMatrixConvertor';
import { HSL_MATRIX_CONVERSION_RULE } from '@/components/settingsChapters/viewChapter/structures/hsl';

type MainColorType = { [K: string]: number };

const skyBlue = new URL('@/assets/images/backgrounds/1.jpeg', import.meta.url).href;
const skyDark = new URL('@/assets/images/backgrounds/2.jpg', import.meta.url).href;
const backgroundDark = new URL('@/assets/images/backgrounds/3.jpg', import.meta.url).href;
const backgroundLight = new URL('@/assets/images/backgrounds/4.jpg', import.meta.url).href;
const mountsDark = new URL('@/assets/images/backgrounds/5.jpg', import.meta.url).href;

const root: HTMLElement | null = document.querySelector(':root');
export let settingsName: string = '';
let previousBackground: string = '';
let surfaceOpacity: string[];
let isBackgroundImage: boolean = false;
const images: { [key: string]: string } = {
    'sky-blue': skyBlue,
    'sky-dark': skyDark,
    'background-dark': backgroundDark,
    'background-light': backgroundLight,
    'mounts-dark': mountsDark
};
let mainCol: MainColorType;

const hsbToHsl = (h: number, s: number, b: number) => {
    const x: number = ((200 - s) * b) / 100;
    return {
        h,
        s: x === 0 || x === 200 ? 0 : Math.round((s * b) / (x <= 100 ? x : 200 - x)),
        l: Math.round(x / 2)
    };
};

export const setPreviousBackground = () => {
    // Данная функция очищает фоновую картинку при выключении использования картинки
    if (root) previousBackground = root.style.getPropertyValue('--surface-ground');
};

export const setSurfaceOpacity = () => {
    /*Данная функция добавляет прозрачность элементам интерфейса. Используется при установке картинки на фон приложения*/
    if (isBackgroundImage && root) {
        root.style.removeProperty('--surface-opacity');
        const rootStyle = getComputedStyle(root);
        surfaceOpacity = rootStyle.getPropertyValue('--surface-opacity').split(',');
        surfaceOpacity.length = 3;
        root.style.setProperty('--surface-opacity', `${surfaceOpacity[0]}, ${surfaceOpacity[1]}, ${surfaceOpacity[2]}, 0.65)`);
    }
};

export const setPrimaryColor = (mainColor: MainColorType) => {
    /* Данная функция устанавливает новые цвета вместо изначальных
     * * mainColor - цвет в формате hsb или hsl который устанавливает основной цвет. Из данного цвета высчитываются остальные цвета*/
    if (mainColor) {
        mainCol = mainColor;
        const hslColor = mainCol.l ? mainCol : hsbToHsl(mainCol.h, mainCol.s, mainCol.b);
        if (root) {
            hslMatrixConvertor(HSL_MATRIX_CONVERSION_RULE, root, [hslColor.h, hslColor.s, hslColor.l]);
            root.style.setProperty('--primary-color', `hsl(${hslColor.h + 'deg ' + hslColor.s + '% ' + hslColor.l + '%'})`);
            root.style.setProperty('--blue-50', `hsla(${hslColor.h + ', ' + hslColor.s + '%, ' + (hslColor.l - 15) + '%, '} 0.05)`);
            root.style.setProperty('--blue-900', `hsla(${hslColor.h + ', ' + hslColor.s + '%, ' + (hslColor.l - 15) + '%, '} 0.1)`);
            root.style.setProperty('--background-calendar', `hsla(${hslColor.h + ', ' + hslColor.s + '%, ' + (hslColor.l - 15) + '%, '} 0.15)`);
            root.style.setProperty('--background-calendar-current', `hsla(${hslColor.h + ', ' + hslColor.s + '%, ' + (hslColor.l - 15) + '%, '} 0.4)`);
        }
    }
};

export const setBackgroundImage = (newSettingsName?: string | boolean, isCustom = false) => {
    // Данная функция устанавливает изображение на фон
    if (newSettingsName && !isCustom) {
        if (newSettingsName !== 'no-image') {
            settingsName = newSettingsName !== true ? newSettingsName : settingsName;
            if (settingsName && settingsName !== 'no-image') {
                if (root) {
                    root.style.removeProperty('--surface-ground');
                    setPreviousBackground();
                    root.style.setProperty('--surface-ground', `center no-repeat url("${images[settingsName]}")`);
                    isBackgroundImage = true;
                    setSurfaceOpacity();
                }
            }
        } else {
            settingsName = newSettingsName;
            if (root) {
                isBackgroundImage = false;
                root.style.setProperty('--surface-ground', previousBackground);
                root.style.removeProperty('--surface-opacity');
            }
        }
    } else if (newSettingsName && isCustom && newSettingsName !== 'no-image') {
        if (root) {
            root.style.removeProperty('--surface-ground');
            setPreviousBackground();
            root.style.setProperty('--surface-ground', `center no-repeat url("${newSettingsName}")`);
            isBackgroundImage = true;
            setSurfaceOpacity();
        }
    }
    return settingsName;
};
