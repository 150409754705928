import { defineStore } from 'pinia';
import { ref } from "vue";
import { notifications } from '@/api/requests/notifications';
import { eventSourceConnecting } from "@/stores/notifications/eventSourceConnecting";
import { NotificationByChainItemType, NotificationPayloadType } from "@/api/requests/notifications/types";

export type NotificationReportStatusItemType = {
  payload: NotificationPayloadType & { downloadUrl: string; name: string };
  chain: {
    chainId: string;
    notificationType: string
  }
} & NotificationByChainItemType

export const useUnreadNotificationsReportStatusStore = defineStore('unread-notifications-report', () => {
  const reportNotifications = ref<NotificationReportStatusItemType[]>([]);

  const fetchReportStatusNotifications = async () => {
      const params = {
          pageSize: 40,
          pageNumber: 1,
          notificationType: 'report_status'
      };
      const { data: { data: { items } }} = await notifications.getNotifications({ params });
      reportNotifications.value = items;
  };

  eventSourceConnecting('report_status', reportNotifications);

  return {
      fetchReportStatusNotifications,
      reportNotifications
  };
});
