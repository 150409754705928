

const DIRECTORY_PRODUCERS_ROUTES = {
	path: "directory/producer",
	name: "directory-producer",
	redirect: { name: "producer-list" },
	component: () => import("@/views/pages/Directories/producer/DirectoryProducerPage.vue"),
	children: [
		{
			path: "list",
			name: "producer-list",
			meta: { transition: "slide-left" },
			component: () => import("@/views/pages/Directories/producer/list/DirectoryProducerListPage.vue"),
		},
		{
			path: ":producerId",
			name: "current-producers-producer",
			meta: { transition: "slide-right" },
			component: () => import("@/views/pages/Directories/producer/current/DirectoryProducerCurrentPage.vue"),
			children: [
				{
					path: "producer",
					name: "current-producer",
					component: () => import("@/views/pages/Directories/producer/current/tabs/basic/tab.tsx"),
				},
				{
					path: "goods",
					name: "current-producer-goods",
					component: () => import("@/views/pages/Directories/producer/current/tabs/goods/tab.tsx"),
				},
			],
		},
	],
};

export default DIRECTORY_PRODUCERS_ROUTES;