import type { AxiosResponse } from "axios";

import http from "@/api/instance";
import type { BaseRequestConfigType } from "@/api/requests/filters/shorts/types";
import type { NotificationByChainItemType } from "@/api/requests/notifications/types";

type NotificationsByChainIdParamsType = {
	chainId: string[];
	readFilter: string;
};

export type NotificationsByChainIdResponseDataItemType = {
	chainId: string;
	notificationType: string;
	notifications: NotificationByChainItemType[];
};

type NotificationsByChainIdResponseType = { data: NotificationsByChainIdResponseDataItemType };

type NotificationsByChainIdRequestType = (args: { params: NotificationsByChainIdParamsType } & BaseRequestConfigType) => Promise<AxiosResponse<NotificationsByChainIdResponseType>>;
export const getNotificationsByChainId: NotificationsByChainIdRequestType = ({ params, cancelToken }, chainId) =>
	http.httpCore.get(`notifications/chain/${chainId}`, { params, cancelToken });
