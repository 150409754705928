const REALIZATION_ROUTES = {
    path: 'realization/shipments-and-bonuses',
    name: 'realization',
    redirect: { name: 'realization-dki' },
    component: () => import('@/views/pages/Realization/shipmentsAndBonuses/RealizationPage.vue'),
    children: [
        {
            path: 'dki',
            name: 'realization-dki',
            component: () => import('@/views/pages/Realization/shipmentsAndBonuses/tabs/dki/DKITab.tsx')
        },
        {
            path: 'contractor',
            name: 'realization-contractor',
            component: () => import('@/views/pages/Realization/shipmentsAndBonuses/tabs/contractor/ContractorTab.tsx'),
            props: true
        },
        {
            path: 'department',
            name: 'realization-department',
            component: () => import('@/views/pages/Realization/shipmentsAndBonuses/tabs/department/DepartmentTab.tsx'),
            props: true
        },
        {
            path: 'invoice',
            name: 'realization-invoice',
            component: () => import('@/views/pages/Realization/shipmentsAndBonuses/tabs/invoice/InvoiceTab.tsx'),
            props: true
        }
    ]
};

export default REALIZATION_ROUTES;
