import { defineComponent, ref, onBeforeUnmount, onMounted, watch } from 'vue';
import skyBlue from '@/assets/images/backgrounds/1.jpeg';
import skyDark from '@/assets/images/backgrounds/2.jpg';
import backgroundDark from '@/assets/images/backgrounds/3.jpg';
import backgroundLight from '@/assets/images/backgrounds/4.jpg';
import mountsDark from '@/assets/images/backgrounds/5.jpg';
import { setBackgroundImage } from '@/components/settingsChapters/viewChapter/utils/setColors';
import { saveBackgroundImageProperties } from '@/components/settingsChapters/viewChapter/utils/changeTheme';
import Download from '@/components/settingsChapters/viewChapter/modals/download.vue';
import { useToast } from 'primevue/usetoast';
import { useCentralSettingsStore } from '@/stores/centralSettingsStore';
import { storeToRefs } from 'pinia';

const BackgroundImage = defineComponent({
    name: 'BackgroundImage',
    setup() {
        const currentImage = ref('no-image');
        const turnOnImage = ref(false);
        const showModal = ref(false);
        const toast = useToast();
        const centralSettings = useCentralSettingsStore();
        const { isBackgroundImage } = storeToRefs(centralSettings);

        watch(
            () => turnOnImage.value,
            (newValue) => (isBackgroundImage.value = newValue)
        );

        onMounted(() => {
            currentImage.value = setBackgroundImage();
            if (currentImage.value && currentImage.value !== 'no-image') turnOnImage.value = true;
        });

        const turnOffImage = () => {
            if (!turnOnImage.value) currentImage.value = setBackgroundImage('no-image');
        };

        onBeforeUnmount(() => {
            saveBackgroundImageProperties(currentImage.value);
        });

        return () => (
            <div style="min-height: 39rem;" class="settings-chapter">
                <div class="grid settings-chapter-header">
                    <div class={['col-12']}>
                        <div class="flex align-items-center justify-content-between mb-3">
                            <h4 class="mb-0">Фоновое изображение</h4>
                            <InputSwitch class="" v-model={turnOnImage.value} onChange={() => turnOffImage()} />
                        </div>
                    </div>
                </div>
                <div class="grid">
                    <div
                        onClick={() => (currentImage.value = turnOnImage.value ? setBackgroundImage('sky-blue') : 'no-image')}
                        class={['col-6', turnOnImage.value ? 'theme-button' : '', currentImage.value === 'sky-blue' && turnOnImage.value ? 'active-theme' : 'deactive-theme', !turnOnImage.value ? 'blur-image' : '']}
                    >
                        <img src={skyBlue} class="w-12 h-8rem border-round-sm" />
                    </div>
                    <div
                        onClick={() => (currentImage.value = turnOnImage.value ? setBackgroundImage('sky-dark') : 'no-image')}
                        class={['col-6', turnOnImage.value ? 'theme-button' : '', currentImage.value === 'sky-dark' && turnOnImage.value ? 'active-theme' : 'deactive-theme', !turnOnImage.value ? 'blur-image' : '']}
                    >
                        <img src={skyDark} class="w-12 h-8rem border-round-sm" />
                    </div>
                    <div
                        onClick={() => (currentImage.value = turnOnImage.value ? setBackgroundImage('background-dark') : 'no-image')}
                        class={['col-6', turnOnImage.value ? 'theme-button' : '', currentImage.value === 'background-dark' && turnOnImage.value ? 'active-theme' : 'deactive-theme', !turnOnImage.value ? 'blur-image' : '']}
                    >
                        <img src={backgroundDark} class="w-12 h-8rem border-round-sm" />
                    </div>
                    <div
                        onClick={() => (currentImage.value = turnOnImage.value ? setBackgroundImage('background-light') : 'no-image')}
                        class={['col-6', turnOnImage.value ? 'theme-button' : '', currentImage.value === 'background-light' && turnOnImage.value ? 'active-theme' : 'deactive-theme', !turnOnImage.value ? 'blur-image' : '']}
                    >
                        <img src={backgroundLight} class="w-12 h-8rem border-round-sm" />
                    </div>
                    <div
                        onClick={() => (currentImage.value = turnOnImage.value ? setBackgroundImage('mounts-dark') : 'no-image')}
                        class={['col-6', turnOnImage.value ? 'theme-button' : '', currentImage.value === 'mounts-dark' && turnOnImage.value ? 'active-theme' : 'deactive-theme', !turnOnImage.value ? 'blur-image' : '']}
                    >
                        <img src={mountsDark} class="w-12 h-8rem border-round-sm" />
                    </div>
                    <div
                        onClick={() => {
                            // if (turnOnImage.value) showModal.value = true
                            toast.add({ severity: 'warn', summary: 'Данный функционал в разработке', detail: 'Данный функционал находится в разработке, в будущем будет возможно добавление индивидуальной картинки в качестве фона', life: 5000 });
                        }}
                        class={['col-6', !turnOnImage.value ? 'blur-image' : 'theme-button']}
                    >
                        <div class={['w-12 h-8rem border-round-sm text-center no-background-image', turnOnImage.value ? 'transition-colors transition-duration-200 hover:text-primary' : '']}>
                            <span class="material-icons-round lg:mt-1" style="line-height: 5rem; font-size: 2.75rem;">
                                downloading
                            </span>
                            <p class="text-center download-image-text">Загрузить изображение</p>
                        </div>
                    </div>
                    <Download visible={showModal.value} onDialog:close={(evt: boolean) => (showModal.value = evt)} />
                </div>
            </div>
        );
    }
});

export default BackgroundImage;
