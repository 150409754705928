import { defineComponent, onMounted, ref, watch } from 'vue';
import systemTheme from '@/assets/images/themesImages/ProSystemSystem.svg';
import darkTheme from '@/assets/images/themesImages/ProSystemDark.svg';
import blackTheme from '@/assets/images/themesImages/ProSystemBlack.svg';
import lightTheme from '@/assets/images/themesImages/ProSystemLight.svg';
import { useLayout } from '@/layout/composables/layout';
import { onChangeTheme } from '@/components/settingsChapters/viewChapter/utils/changeTheme';
import InputSwitch from 'primevue/inputswitch';

const Theme = defineComponent({
    name: 'Theme',
    setup() {
        const { changeThemeSettings, layoutConfig, changeHolidayTheme } = useLayout();

        const useSystemTheme = () => {
            let mode;
            let theme;
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                mode = 'dark';
            } else {
                mode = 'light';
            }
            onChangeTheme('systemTheme', mode);
            changeThemeSettings('systemTheme', mode === 'dark');
        };

        return () => (
            <div class={['grid settings-chapter', layoutConfig.value.theme === 'system-theme' ? 'active-theme' : 'deactive-theme']}>
                <div onClick={() => useSystemTheme()} class={['col-6 text-center theme-button', layoutConfig.value.theme === 'systemTheme' ? 'active-theme' : 'deactive-theme']}>
                    <img src={systemTheme} class="w-12" alt={'Системная тема'} />
                    <p>Системная тема</p>
                </div>
                <div onClick={() => onChangeTheme('lara-dark-blue', 'dark')} class={['col-6 text-center theme-button', layoutConfig.value.theme === 'lara-dark-blue' ? 'active-theme' : 'deactive-theme']}>
                    <img src={darkTheme} class="w-12" alt={'Темная тема'} />
                    <p>Темная тема</p>
                </div>
                <div onClick={() => onChangeTheme('arya-blue', 'dark')} class={['col-6 text-center theme-button', layoutConfig.value.theme === 'arya-blue' ? 'active-theme' : 'deactive-theme']}>
                    <img src={blackTheme} class="w-12" alt={'Черная тема'} />
                    <p>Черная тема</p>
                </div>
                <div onClick={() => onChangeTheme('lara-light-blue', 'light')} class={['col-6 text-center theme-button', layoutConfig.value.theme === 'lara-light-blue' ? 'active-theme' : 'deactive-theme']}>
                    <img src={lightTheme} class="w-12" alt={'Светлая тема'} />
                    <p>Светлая тема</p>
                </div>
                {/*<div class="pt-4">*/}
                {/*    <div class="text-left flex align-items-center">*/}
                {/*        <InputSwitch input-id="holidayTheme" modelValue={layoutConfig.value.holidayTheme} onInput={(value: boolean) => changeHolidayTheme(value)} />*/}
                {/*        <label for="holidayTheme" class="mx-2 vertical-align-top">*/}
                {/*            Праздничное оформление*/}
                {/*        </label>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        );
    }
});

export default Theme;
