<script setup lang="ts">
import { computed, watch, ref, defineAsyncComponent } from "vue";

import AppTopbar from '@/layout/AppTopbar.vue';
import AppFooter from '@/layout/AppFooter.vue';
import AppConfig from '@/layout/AppConfig.vue';

const AppSidebar = defineAsyncComponent(() => import('@/layout/AppSidebar.vue'));

import { useLayout } from '@/layout/composables/layout';

interface ClickEvent extends MouseEvent {
    target: HTMLElement;
}

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const visible = ref<boolean>(false);
const themeTypeChangeAbility = ref<boolean>(true);

watch(isSidebarActive, (newVal: boolean) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

const containerClass = computed(() => {
    return {
        'layout-theme-light': !layoutConfig.value.darkTheme,
        'layout-theme-dark': layoutConfig.value.darkTheme,
        'layout-overlay': layoutConfig.value.menuMode === 'overlay',
        'layout-static': layoutConfig.value.menuMode === 'static',
        'layout-static-inactive': layoutState.value.staticMenuDesktopInactive && layoutConfig.value.menuMode === 'static',
        'layout-overlay-active': layoutState.value.overlayMenuActive,
        'layout-mobile-active': layoutState.value.staticMenuMobileActive,
        'p-input-filled': layoutConfig.value.inputStyle === 'filled',
        'p-ripple-disabled': !layoutConfig.value.ripple
    };
});

const outsideClickListener = (event: MouseEvent) => {
    if (isOutsideClicked(event)) {
        layoutState.value.overlayMenuActive = false;
        layoutState.value.staticMenuMobileActive = false;
        layoutState.value.menuHoverActive = false;
    }
};

const bindOutsideClickListener = (): void => {
    if (!document.onclick) {
        document.addEventListener('click', outsideClickListener);
    }
};

const unbindOutsideClickListener = (): void => {
    if (outsideClickListener) {
        document.removeEventListener('click', outsideClickListener);
    }
};

const isOutsideClicked = (event): boolean => {
    const sidebarEl = document.querySelector('.layout-sidebar') as HTMLElement;
    const topbarEl = document.querySelector('.layout-menu-button') as HTMLElement;

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

const onConfigButtonClick = (): void => {
    visible.value = !visible.value;
};
</script>

<template>
    <ScrollPanel style="width: 100%; height: 100vh" class="custombar">
        <div class="layout-wrapper" :class="containerClass">
        <Toast />
        <AppTopbar @onConfig:button="onConfigButtonClick" :theme-type-change-ability="themeTypeChangeAbility"/>
        <div class="layout-sidebar py-3 scroll-bar">
            <AppSidebar />
        </div>
        <div class="layout-main-container">
            <div class="layout-main scroll-bar">
                <router-view />
            </div>
            <AppFooter/>
        </div>
        <AppConfig :visible="visible" @update:visible="onConfigButtonClick" @update:theme-ability="ability => {themeTypeChangeAbility = ability}"/>
        <div class="layout-mask"></div>
    </div>
    </ScrollPanel>
</template>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel.custombar .p-scrollpanel-wrapper) {
    border-right: 10px solid var(--surface-ground);
}

::v-deep(.p-scrollpanel.custombar .p-scrollpanel-bar) {
    background-color: var(--primary-300);
    opacity: 1;
    transition: background-color 0.3s;
}

::v-deep(.p-scrollpanel.custombar .p-scrollpanel-bar:hover) {
    background-color: var(--primary-400);
}
</style>
