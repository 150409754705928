import { defineStore } from "pinia";
import { ref } from "vue";
import { notifications } from "@/api/requests/notifications";
import { NotificationByChainItemType, NotificationPayloadType } from "@/api/requests/notifications/types";
import { eventSourceConnecting } from "@/stores/notifications/eventSourceConnecting";

export type NotificationMarketingEventItemType = {
  payload: NotificationPayloadType;
  chain: {
    chainId: string;
    notificationType: string
  }
} & NotificationByChainItemType

export const useUnreadNotificationEventWizardStatusStore = defineStore('unread-notifications-event',  () => {
  const marketingEventsNotifications = ref<NotificationMarketingEventItemType[]>([]);
  const params = {
    pageSize: 40,
    pageNumber: 1,
    notificationType: 'event_wizard_status'
  };

  const fetchMarketingEventsNotifications = async () => {
    const { data: { data: { items } }} = await notifications.getNotifications({ params });
    marketingEventsNotifications.value = items;
  }

  eventSourceConnecting('event_wizard_status', marketingEventsNotifications);

  return {
    fetchMarketingEventsNotifications,
    marketingEventsNotifications
  };
})
