import type { AxiosResponse } from "axios";

import http from "@/api/instance";
import type { BaseRequestConfigType } from "@/api/requests/filters/shorts/types";

type NotificationsReadResponseType = { data: number };

type NotificationsReadRequestType = (args: BaseRequestConfigType) => Promise<AxiosResponse<NotificationsReadResponseType>>;

export const readNotifications: NotificationsReadRequestType = ({ cancelToken }) => http.httpCore.get(`notifications/read`, { cancelToken });
