import {defineComponent, onBeforeMount, onBeforeUnmount, onMounted, ref, watch} from "vue";
import lightTheme from "@/assets/images/themesImages/ProSystemLight.svg";
import { onChangeTheme, saveIndividualColorsProperties } from "@/components/settingsChapters/viewChapter/utils/changeTheme";
import {useLayout} from "@/layout/composables/layout";
import {useCentralSettingsStore} from "@/stores/centralSettingsStore";
import { setPrimaryColor, clearIndividualTheme } from "@/components/settingsChapters/viewChapter/utils/setColors";
import {storeToRefs} from "pinia";

const IndividualTheme = defineComponent({
    name: 'IndividualTheme',
    setup(){
        const { layoutConfig } = useLayout();
        const centralSettings = useCentralSettingsStore();
        const mainColor = ref({h:217, s:76, b:97})
        const { individualColorsThemes } = storeToRefs(centralSettings);

        const setIndividualTheme = () => {
            setPrimaryColor(mainColor.value);

            onChangeTheme('individualTheme', 'light')
        }

        watch(
            () => mainColor.value,
            () => {
                setPrimaryColor(mainColor.value)
                if (individualColorsThemes.value) individualColorsThemes.value.primaryColor = mainColor.value
                else {
                    individualColorsThemes.value = {primaryColor: mainColor.value}
                }
            },
            {deep: true}
        )

        onMounted(
            () => {
                mainColor.value = individualColorsThemes.value?.primaryColor;
                setPrimaryColor(mainColor.value);
            }
        )

        onBeforeUnmount(
            () => {
                saveIndividualColorsProperties(mainColor.value);
            }
        )

        return () => (
            <>
                <div class="grid settings-chapter-header">
                    <div class={["col-12", layoutConfig.value.theme === 'individual-theme' ? 'active-theme' : '']}>
                        <h4>Дополнительные цвета</h4>
                    </div>
                </div>
                <div class="grid settings-chapter">
                    <div class="col-12">
                        <div class='grid py-3 ml-1 flex align-items-center'>
                            <ColorPicker v-model={mainColor.value} format='hsb'/>
                            <p class='ml-1'>Основной цвет</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }
})
export default IndividualTheme