const DIRECTORY_CONTRACTORS_GROUP_ROUTES = {
    path: 'directory/contractorsGroup',
    name: 'directory-contractorsGroup',
    redirect: { name: 'contractorsGroup-list' },
    component: () => import('@/views/pages/Directories/contractorsGroup/DirectoryContractorsGroupPage.vue'),
    children: [
        {
            path: 'list',
            name: 'contractorsGroup-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/contractorsGroup/list/DirectoryContractorsGroupListPage.vue')
        },
        {
            path: ':contractorsGroupId',
            name: 'current-contractorsGroup-contractorsGroup',
            meta: { transition: 'slide-right' },
            component: () => import('@/views/pages/Directories/contractorsGroup/current/DirectoryContractorsGroupCurrentPage.vue'),
            children: [
                {
                    path: 'contractorsGroup',
                    name: 'current-contractorsGroup',
                    component: () => import('@/views/pages/Directories/contractorsGroup/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'contractors',
                    name: 'current-contractorsGroup-contractors',
                    component: () => import('@/views/pages/Directories/contractorsGroup/current/tabs/contractor/tab.tsx')
                }
            ]
        }
    ]
};

export default DIRECTORY_CONTRACTORS_GROUP_ROUTES;
