import {defineComponent} from "vue";
import RadioButton from "primevue/radiobutton";
import {useLayout} from "@/layout/composables/layout";

const InputsFormat = defineComponent({
    name: 'InputsFormat',
    setup() {
        const { layoutConfig } = useLayout();

        return () => (
            <>
                <div class="grid settings-chapter-header">
                    <div class="col-12">
                        <h4>Стиль полей ввода</h4>
                    </div>
                </div>
                <div class="grid pl-6 settings-chapter">
                    <div class="col-5 text-left flex align-items-center">
                        <RadioButton input-id="rounded" value="outlined" v-model={layoutConfig.value.inputStyle}/>
                        <label for="rounded" class="ml-2"> Обведено </label>
                    </div>
                    <div class="col-7 text-left flex align-items-center">
                        <RadioButton input-id="filled" value="filled" v-model={layoutConfig.value.inputStyle}/>
                        <label for="filled" class="ml-2"> Заполнено </label>
                    </div>
                </div>
            </>
        )
    }
});

export default InputsFormat