import { OIDCAuthController } from "@proapteka/oidc-auth-controller";
import axios from "axios";
import qs from "qs";

import { proSystemApiUrl } from "@/env.config";

const httpCore = axios.create({
	baseURL: proSystemApiUrl,
	timeout: 10 * 60 * 1000,
	headers: {
		Accept: "application/ld+json",
		"Content-Type": "application/json; charset=utf-8",
		"Cache-Control": "no-cache",
		"Access-Control-Allow-Methods": "GET, POST, OPTIONS, DELETE, PUT",
		"Access-Control-Allow-Headers":
			"access-control-allow-headers,access-control-allow-methods,access-control-expose-headers,cache-control,Authorization,DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range,X-Real-Ip,X-Api-Key,X-Jwt-Token,Accept",
		"Access-Control-Expose-Headers":
			"access-control-allow-headers,access-control-allow-methods,access-control-expose-headers,cache-control,Authorization,DNT,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range,X-Real-Ip,X-Api-Key,X-Jwt-Token,Accept",
	},
	withCredentials: true,
	transformRequest: [(data) => JSON.stringify(data)],
	paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
});

const httpCoreFormData = axios.create({
	baseURL: proSystemApiUrl,
	timeout: 60 * 1000,
	headers: {
		"Content-Type": "multipart/form-data",
	},
	withCredentials: true,
	transformRequest: [(data) => {
		return data;
	}],
});

export const authControllerInstance: OIDCAuthController =  new OIDCAuthController({ Axios: httpCore });
export default { httpCore, httpCoreFormData };
