const ASSORTMENT_PLANS_ROUTES = {
    path: 'assortment-plans',
    name: 'assortment-plans',
    redirect: { name: 'plan-list' },
    component: () => import('@/views/pages/AssortmentPlans/AssortmentsPlansPage.vue'),
    children: [
        {
            path: 'list',
            name: 'plan-list',
            meta: { transition: 'slide-right' },
            component: () => import('@/views/pages/AssortmentPlans/list/AssortmentPlansListPage.vue')
        },
        {
            path: ':planId',
            name: 'current-plans-plan',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/AssortmentPlans/current/AssortmentPlansCurrentPage.vue'),
            children: [
                {
                    path: 'goods',
                    name: 'current-plan-goods',
                    component: () => import('@/views/pages/AssortmentPlans/current/tabs/goods/tab')
                },
                {
                    path: 'distributors',
                    name: 'current-plan-distributors',
                    component: () => import('@/views/pages/AssortmentPlans/current/tabs/distributors/tab')
                }
            ]
        }
    ]
};

export default ASSORTMENT_PLANS_ROUTES;
