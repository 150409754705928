import {defineComponent, ref} from "vue";
import Button from "primevue/button";
import {useLayout} from "@/layout/composables/layout";

const Scale = defineComponent({
    name: 'Scale',
    setup() {
        const { setScale, layoutConfig } = useLayout();
        const scales = ref([10, 11, 12, 13, 14, 15, 16]);

        const decrementScale = (): void => {
            setScale(layoutConfig.value.scale - 1);
            applyScale();
        };

        const incrementScale = (): void => {
            setScale(layoutConfig.value.scale + 1);
            applyScale();
        };

        const applyScale = (): void => {
            document.documentElement.style.fontSize = layoutConfig.value.scale + 'px';
        };

        return () => (
            <>
                <div class="grid settings-chapter-header">
                    <div class="col-12">
                        <h4>Масштаб</h4>
                    </div>
                </div>
                <div class="grid pl-6 settings-chapter">
                    <div class="flex align-items-center">
                        <Button icon="pi pi-minus" type="button" onClick={() => decrementScale() }
                                class="p-button-text p-button-rounded w-2rem h-2rem md:mr-2 mr-1"
                                disabled={layoutConfig.value.scale === scales.value[0]} />
                    </div>
                    <div class="col-7 md:col-8 text-center">
                        <div class="flex gap-1 md:gap-2 align-items-center justify-content-center">
                            {
                                scales.value.map(s => {
                                    return <i key={s} class={["pi pi-circle-fill text-300", s === layoutConfig.value.scale ? 'text-primary-500' : '']}></i>
                                })
                            }
                        </div>
                    </div>
                    <div class="flex align-items-center">
                        <Button icon="pi pi-plus" type="button" onClick={() => incrementScale()}
                                class="p-button-text p-button-rounded w-2rem h-2rem md:ml-2 ml-1"
                                disabled={layoutConfig.value.scale === scales.value[scales.value.length - 1]} />
                    </div>
                </div>
            </>
        )
    }
});

export default Scale