// Эталоном взят --primary-500 hex(#00e08f) hsl(158, 100%, 44%), стандартный цвет primeVue.

// Матрица представляет собой последовательность hsl параметров различных с эталонными параметрами hsl

export const HSL_MATRIX_CONVERSION_RULE = [
    ['0', '-21', '+51'],
    ['+1', '-21', '+45'],
    ['0', '-21', '+34'],
    ['+1', '-22', '+22'],
    ['0', '-22', '+11'],
    ['0', '0', '0'], // - эталонный hsl
    ['0', '0', '-9'],
    ['+1', '0', '-18'],
    ['0', '0', '-26'],
    ['+1', '0', '-35']
];
