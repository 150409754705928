class Snowflake {
    private x: number;
    private y: number;
    private vx: number;
    private vy: number;
    private radius: number;
    private alpha: number;
    constructor() {
        this.x = 0;
        this.y = 0;
        this.vx = 0;
        this.vy = 0;
        this.radius = 0;
        this.alpha = 0;

        this.reset();
    }

    reset() {
        this.x = this.randBetween(0, window.innerWidth);
        this.y = this.randBetween(0, -window.innerHeight);
        this.vx = this.randBetween(-3, 3);
        this.vy = this.randBetween(2, 5); // Скорость падения снежинок (стандартный - 5)
        this.radius = this.randBetween(1, 4);
        this.alpha = this.randBetween(0.1, 0.9);
    }

    randBetween(min: number, max: number) {
        return min + Math.random() * (max - min);
    }

    update() {
        this.x += this.vx;
        this.y += this.vy;

        if (this.y + this.radius > window.innerHeight) {
            this.reset();
        }
    }
}

export class Snow {
    private readonly canvas: HTMLCanvasElement;
    private ctx: CanvasRenderingContext2D;
    private width: number;
    private height: number;
    private snowflakes: any[];
    private readonly animationId: number = 0;
    private isSnowing: boolean = true;
    constructor() {
        this.canvas = document.createElement('canvas');
        this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
        this.width = 0;
        this.height = 0;
        this.snowflakes = [];

        if (!document.getElementById('snow')) {
            this.canvas.setAttribute('id', 'snow');
            document.body.appendChild(this.canvas);
        }

        window.addEventListener('resize', () => this.onResize());
        this.onResize();
        // this.updateBound = this.update.bind(this);
        this.animationId = requestAnimationFrame(() => this.update(this));
    }

    onResize() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    createSnowflakes() {
        // Контроль количества снега, деленное на 1 - сильный снегопад (стандартный - 4)
        const flakes = window.innerWidth / 4;

        this.snowflakes = [];

        for (let s = 0; s < flakes; s++) {
            this.snowflakes.push(new Snowflake());
        }
    }

    update(context: any) {
        context.ctx.clearRect(0, 0, context.width, context.height);

        if (context.isSnowing) {
            for (const flake of context.snowflakes) {
                flake.update();

                context.ctx.save();
                context.ctx.fillStyle = '#FFF';
                context.ctx.beginPath();
                context.ctx.arc(flake.x, flake.y, flake.radius, 0, Math.PI * 2);
                context.ctx.closePath();
                context.ctx.globalAlpha = flake.alpha;
                context.ctx.fill();
                context.ctx.restore();
            }
            requestAnimationFrame(() => context.update(context));
        }
    }

    remove() {
        this.isSnowing = false;
        this.canvas.remove();
        cancelAnimationFrame(this.animationId);
        this.snowflakes = [];
    }
}
