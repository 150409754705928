import { getNotificationsByChainId } from "@/api/requests/notifications/chain/getNotificationByChainId";
import { readNotificationsByChainId } from "@/api/requests/notifications/chain/readNotificationByChainId";
import { getNotificationById } from "@/api/requests/notifications/fetch/getNotificationById";
import { getNotifications } from "@/api/requests/notifications/fetch/getNotifications";
import { readNotificationById } from "@/api/requests/notifications/fetch/readNotificationById";
import { readNotifications } from "@/api/requests/notifications/fetch/readNotifications";

export const notifications = {
	getNotifications,
	getNotificationById,
	readNotifications,
	readNotificationById,
	getNotificationsByChainId,
	readNotificationsByChainId
};