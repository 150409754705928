

const DIRECTORY_DISTRIBUTORS_ROUTES = {
  path: 'directory/distributor',
  name: 'directory-distributor',
  redirect: {name: 'distributor-list'},
  component: () => import('@/views/pages/Directories/distributor/DirectoryDistributorPage.vue'),
  children: [
    {
      path: 'list',
      name: 'distributor-list',
      meta: {transition: 'slide-left'},
      component: () => import('@/views/pages/Directories/distributor/list/DirectoryDistributorListPage.vue')
    },
    {
      path: ':distributorId',
      name: 'current-distributors-distributor',
      meta: {transition: 'slide-right'},
      component: () => import('@/views/pages/Directories/distributor/current/DirectoryDistributorCurrentPage.vue'),
      children: [
        {
          path: 'distributor',
          name: 'current-distributor',
          component: () => import('@/views/pages/Directories/distributor/current/tabs/main/tab.vue')
        },
        {
          path: 'mapping',
          name: 'current-distributor-mapping',
          component: () => import('@/views/pages/Directories/distributor/current/tabs/mapping/tab.vue')
        },
      ]
    }
  ]
};

export default DIRECTORY_DISTRIBUTORS_ROUTES;