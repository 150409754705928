import http from '@/api/instance';

export const downloadFile = async (link: string, label: string) => {
  const regex: RegExp = /(.*ru\/api\/|.*ru\/)(.*)$/;
  const [ baseURL, host, path]: [baseUrl: string | undefined, host: string, path: string] = link.match(regex) || [];
  if (!baseURL) return;
  http.httpCore.get(path, { responseType: 'blob', baseURL: host }).then(({data}) => {
    const url: string = window.URL.createObjectURL(new Blob([data]));
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.setAttribute('download', label);
    document.body.appendChild(link);
    link.click();
  });
}