import { computed, type Ref, ref, ComputedRef } from 'vue';
import { Snow } from '@/holidays/newYear/snow';

const snow = new Snow();
interface LayoutConfig {
    ripple: boolean;
    darkTheme: boolean;
    inputStyle: string;
    menuMode: 'static' | 'overlay';
    theme: string;
    scale: number;
    activeMenuItem: string | null;
    holidayTheme: boolean;
}

interface LayoutState {
    staticMenuDesktopInactive: boolean;
    overlayMenuActive: boolean;
    profileSidebarVisible: boolean;
    configSidebarVisible: boolean;
    staticMenuMobileActive: boolean;
    menuHoverActive: boolean;
}

const layoutConfig = ref<LayoutConfig>({
    ripple: false,
    darkTheme: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    theme: 'lara-light-blue',
    scale: 14,
    activeMenuItem: null,
    holidayTheme: true
});

const layoutState = ref<LayoutState>({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false
});

export function useLayout(): {
    layoutConfig: Ref<LayoutConfig>;
    layoutState: Ref<LayoutState>;
    changeThemeSettings: (theme: string, darkTheme: boolean) => void;
    changeHolidayTheme: (status: boolean) => void;
    setScale: (scale: number) => void;
    setActiveMenuItem: (item: string | null) => void;
    onMenuToggle: () => void;
    isSidebarActive: ComputedRef<boolean>;
    isDarkTheme: ComputedRef<boolean>;
} {
    const changeThemeSettings = (theme: string, darkTheme: boolean): void => {
        layoutConfig.value.darkTheme = darkTheme;
        layoutConfig.value.theme = theme;
    };

    const changeHolidayTheme = (status: boolean): void => {
        layoutConfig.value.holidayTheme = status;
        if (status) snow.createSnowflakes();
        else snow.remove();
    };

    const setScale = (scale: number): void => {
        layoutConfig.value.scale = scale;
    };

    const setActiveMenuItem = (item: string | null): void => {
        layoutConfig.value.activeMenuItem = item;
    };

    const onMenuToggle = (): void => {
        if (layoutConfig.value.menuMode === 'overlay') {
            layoutState.value.overlayMenuActive = !layoutState.value.overlayMenuActive;
        }

        if (window.innerWidth > 991) {
            layoutState.value.staticMenuDesktopInactive = !layoutState.value.staticMenuDesktopInactive;
        } else {
            layoutState.value.staticMenuMobileActive = !layoutState.value.staticMenuMobileActive;
        }
    };

    const isSidebarActive = computed(() => layoutState.value.overlayMenuActive || layoutState.value.staticMenuMobileActive);

    const isDarkTheme = computed(() => layoutConfig.value.darkTheme);

    return {
        layoutConfig,
        layoutState,
        changeThemeSettings,
        setScale,
        setActiveMenuItem,
        onMenuToggle,
        changeHolidayTheme,
        isSidebarActive,
        isDarkTheme
    };
}
