

const DIRECTORY_GOODS_ROUTES = {
	path: "directory/goods",
	name: "directory-goods",
	redirect: { name: "goods-list" },
	component: () => import("@/views/pages/Directories/goods/directory/DirectoryGoodsPage.vue"),
	children: [
		{
			path: "list",
			name: "goods-list",
			meta: { transition: "slide-left" },
			component: () => import("@/views/pages/Directories/goods/directory/list/DirectoryGoodsListPage.vue"),
		},
		{
			path: ":goodId",
			name: "current-goods-good",
			meta: { transition: "slide-right" },
			component: () => import("@/views/pages/Directories/goods/directory/current/DirectoryGoodsCurrentPage.vue"),
			children: [
				{
					path: "good",
					name: "current-good",
					component: () => import("@/views/pages/Directories/goods/directory/current/tabs/good/tab.tsx"),
				},
				{
					path: "barcode",
					name: "current-good-barcode",
					component: () => import("@/views/pages/Directories/goods/directory/current/tabs/barcode/tab.tsx"),
				},
				{
					path: "categories",
					name: "current-good-categories",
					component: () => import("@/views/pages/Directories/goods/directory/current/tabs/categories/tab.tsx"),
				},
				{
					path: "actual",
					name: "current-good-actual-good",
					component: () => import("@/views/pages/Directories/goods/directory/current/tabs/actualGood/tab.tsx"),
				},
				{
					path: "attributes",
					name: "current-good-attributes",
					component: () => import("@/views/pages/Directories/goods/directory/current/tabs/attributes/tab.tsx"),
				},
			],
		},
	],
};

export default DIRECTORY_GOODS_ROUTES;
