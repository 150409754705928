const DIRECTORY_DEPARTMENT_TYPES_ROUTES = {
    path: 'directory/departmentTypes',
    name: 'directory-departmentTypes',
    redirect: { name: 'departmentTypes-list' },
    component: () => import('@/views/pages/Directories/departmentTypes/DirectoryDepartmentTypesPage.vue'),
    children: [
        {
            path: 'list',
            name: 'departmentTypes-list',
            meta: { transition: 'slide-left' },
            component: () => import('@/views/pages/Directories/departmentTypes/list/DirectoryDepartmentTypesListPage.vue')
        },
        {
            path: ':departmentTypesId',
            name: 'current-departmentTypes-departmentType',
            meta: { transition: 'slide-right' },
            // redirect: { name: 'current-event-basic' },
            component: () => import('@/views/pages/Directories/departmentTypes/current/DirectoryDepartmentTypesCurrentPage.vue'),
            children: [
                {
                    path: 'departmentTypes',
                    name: 'current-departmentTypes',
                    component: () => import('@/views/pages/Directories/departmentTypes/current/tabs/basic/tab.tsx')
                },
                {
                    path: 'department',
                    name: 'current-departmentTypes-departments',
                    component: () => import('@/views/pages/Directories/departmentTypes/current/tabs/department/tab.tsx')
                }
            ]
        }
    ]
};

export default DIRECTORY_DEPARTMENT_TYPES_ROUTES;
